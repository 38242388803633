export class User {
    constructor(exists, name, surname, secondSurname, birthdate) {
        this.exists = exists;
        this.name = name;
        this.surname = surname;
        this.secondSurname = secondSurname;
        this.birthdate = birthdate;
    }

    static fromDataBase(doc) {
        console.log(doc.get('birthday'));
        return new User(true, doc.get('name'), doc.get('surname'), doc.get('secondSurname'), new Date(doc.get('birthday').seconds*1000));
    }

    static nameRegex = /^([A-ZÑÇ]{1}[a-zñç]+)*$/;

    getExists() {
        return this.exists;
    }

    getName() {
        return this.name;
    }

    validateName() {
        return User.nameRegex.test(this.name);
    }

    getSurname() {
        return this.surname;
    }

    validateSurname() {
        return User.nameRegex.test(this.surname);
    }

    getSecondSurname() {
        return this.secondSurname;
    }

    validateSecondSurname() {
        return User.nameRegex.test(this.secondSurname);
    }

    getBirthdate() {
        return this.birthdate;
    }

    getStringBirthdate() {
        try {
            return `${this.birthdate.getFullYear()}-${(this.birthdate.getMonth() + 1).toLocaleString('en-US', {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false
                                      })}-${this.birthdate.getDate().toLocaleString('en-US', {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false
                                      })}`;
        } catch(e) {
            console.error(e);
            return '';
        }
    }

    validateBirthdate() {
        let today = Date.now();
        return (this.birthdate !== "Invalid Date") && !isNaN(this.birthdate) && today > this.birthdate;
    }

    getFullName() {
        return this.name + ' ' + this.surname + ' ' + this.secondSurname;
    }

    updateName(name) {
        this.name = name;
    }

    updateSurname(surname) {
        this.surname = surname;
    }

    updateSecondSurname(secondSurname) {
        this.secondSurname = secondSurname;
    }

    updateBirthdate(birthdate) {
        this.birthdate = birthdate;
    }

    validateAll() {
        return this.validateName() && this.validateSurname() && this.validateSecondSurname() && this.validateBirthdate();
    }
}