import { useNavigate, useSearchParams } from "react-router-dom";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useState } from "react";
import Navbar from "../components/navbar";
import { DrawPaymentItem, Payment } from "../implementation/payment";
import { CustomDividerHorizontal } from "../components/customUi";

import '../style/style.css';

export default function PaymentAccepted() {
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentData, setPaymentData] = useState(undefined);
    const [userr, setUser] = useState(null);

    function redirect() {
        navigate('/course/' + searchParams.get('course'));
    }

    function handleOnUserLoggedIn(user) {
        setState(1);
        setUser(user);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getPaymentData() {
        const payData = await Payment.fromFirestoreLast(userr.uid);

        console.log(payData);
        setPaymentData(payData);
    }

    if (state === 1) {
        if (paymentData === undefined) {
            getPaymentData();

            return <div className="fontUbuntu">
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'15px'}}>
                    <h1 className="title">Procesando el pago</h1>
                    <div style={{height:'10px'}}/>
                    <LoadingBarApp/>
                </div>
            </div>;
        } else if (paymentData === null) {
            return <div className="fontUbuntu">
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'15px'}}>
                    <h1 className="title">Algo ha ido mal</h1>
                    <div style={{height: '20px'}}/>
                    <h2 style={{margin:0}}>Mientras procesabamos el pago algo ha ido mal, si el error persiste pongase en contacto con nosotros.</h2>
                </div>
            </div>;
        } else if (paymentData === false) {
            return <div className="fontUbuntu">
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'15px'}}>
                    <h1 className="title">Pago cancelado</h1>
                    <div style={{height: '20px'}}/>
                    <h2 style={{margin:0}}>Parece que el pago ha sido cancelado o aún no se ha completado.</h2>
                </div>
            </div>;
        } else {
            return <div className="fontUbuntu">
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'15px'}}>
                    <h1 className="title">Pago exitoso</h1>
                    <div style={{marginLeft:'2px'}}>ID del pago: {paymentData.getId()}</div>
                    <CustomDividerHorizontal color="grey" height="1px" extraMargin="10px"/>
                    <h1 style={{margin:0}}>Resumen del pago:</h1>
                    <div style={{marginLeft:'30px'}}>
                        {paymentData.getItems().map((item, index) => {
                            return <DrawPaymentItem key={index} item={item}/>;
                        })}
                        <CustomDividerHorizontal color="grey" height="1px" extraMargin="10px"/>
                        <h1 style={{margin:'0px 10px 0px 0px', textAlign:'end'}}>Total: {(paymentData.getTotal()/100).toFixed(2)}€</h1>
                    </div>
                </div>
            </div>;
        }
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}