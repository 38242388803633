import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react"

export function CheckBoxApp({defaultChecked, mapId, handleChange, disabled, label}) {
    const [checked, setChecked] = useState(defaultChecked);

    const handleCtrl= (event) => {
        setChecked(event.target.checked);
        console.log(mapId + " :: " + event.target.checked);
        handleChange(mapId, event.target.checked);
    }

    return <FormControlLabel control={<Checkbox
        checked={checked}
        onChange={handleCtrl}
        disabled={disabled}
        inputProps={{ 'aria-label': 'controlled' }}
    />} label={label}/>
}