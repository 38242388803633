import { useNavigate } from "react-router-dom";
import AuthProvider from "../components/auth-provider";
import { useState } from "react";
import Navbar from "../components/navbar";
import { auth } from "../firebase/firebase";
import MyCourses from "../components/my-courses";
import LoadingBarApp from "../components/loading";
import { ThemeProvider } from "@emotion/react";
import { createTheme, Fab, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";

export default function HomePage() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    /*
      State
      0: Loading
      1: Logged in
      2: Logged in, loading data
      3: Logged in, data loaded
    */
    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    if (state === 1) {
        return <div>
            <ThemeProvider theme={createTheme({
                palette: {
                    primary: {
                        main: '#708090'
                    }
                }
            })}>
                <Fab color="primary" aria-label="browse" variant="extended" style={{position:'absolute', bottom:'10px', right:'10px'}} onClick={() => {
                    navigate("/browse");
                }}>
                    <SearchIcon/>
                    {t('browse.title')}
                </Fab>
            </ThemeProvider>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <MyCourses myuser={userr}/>
        </div>
    }
    
    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}