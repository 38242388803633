import { Button, Dialog, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import LoadingBarApp from "./loading";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Course } from "../implementation/course";

export default function DialogSelectCourse({ open, onClose, userId, onSelect }) {
    const [courses, setCourses] = useState(null);

    async function getCourses() {
        const newCourses = [];
        const cousersDocs = await getDocs(query(collection(db, 'courses'), where('users', 'array-contains', userId)));
        console.log(cousersDocs);
        await Promise.all(cousersDocs.docs.map(async (docData, index) => {
            newCourses.push(Course.fromDataBase(docData));
        }));
        setCourses(newCourses);
    }

    if (courses === null)
        getCourses();

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>Seleccionar curso</DialogTitle>
        <Divider/>
        {courses !== null
            ? <List>
                {courses.map((course, index) => {
                    return <ListItem key={index}>
                        <Button variant="outlined" style={{width:'-webkit-fill-available'}} onClick={() => {onSelect(course)}}>{course.getTitle()}</Button>
                    </ListItem>
                })}
            </List>
            : <LoadingBarApp/>
        }
    </Dialog>
}