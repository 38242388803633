import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            es: {
                translation: {
                    myCourses: {
                        title: "Mis cursos",
                        noCourses: "No hay ningun curso"
                    },
                    browse: {
                        title: "Explorar",
                        apply: "Aplicar filtros",
                        level: {
                            title: "Nivel del curso",
                            basic: "Básico",
                            basicDedicated: "Básico dedicado",
                            dedicated: "Dedicado",
                            advanced: "Avanzado",
                        },
                        lan: {
                            title: "Idioma del curso",
                            cat: "Catalán",
                            esp: "Español"
                        },
                        progLan: {
                            title: "Lenguajes en el curso",
                        }
                    },
                    account: {
                        title: "Datos personales"
                    },
                    datos: {
                        name: "Nombre",
                        surname: "Apellido",
                        secondSurname: "Segundo apellido",
                        birthdate: "Fecha de nacimiento",
                        send: "Enviar datos"
                    },
                    course: {
                        enter: "Entrar al curso",
                        level: "Nivel",
                        buyNow: "Comprar ahora",
                        contentsIndex: "Índice de contenidos",
                        presentationVideo: "Video de presentación",
                        
                        finish: "Finalización",

                        screens: {
                            finished: {
                                title: "Enhorabuena, has acabado el curso",
                                msg: "Aún no hay una nota final para este curso. Esto significa que puedes reclamar un questionario para subir notas para aquellos modulos con los que no estás satisfecho o puedes solicitar el diploma y cerrar el curso.",
                                diploma: "Solicitar diploma",
                                nota: "Solicitar subir nota"
                            },
                            publishCode: {
                                info: "Aquí es donde debes subir el código que has programado para que podamos corregirlo.",
                                intentsTitle: "Intentos realizados",
                                intentSingular: "intento realizado",
                                intentPlural: "intentos realizados",
                                noIntents: "Aún no se han realizado intentos",
                                max: "intentos como máximo",
                                buttonText: "Subir archivo",
                                buttonChange: "Cambiar archivo",
                                buttonSend: "Enviar archivo",
                                noIntents: "Aún no se ha intentado ninguna vez esta entrega",
                                lastIntent: "Último intento"
                            }
                        },

                        tooltip: {
                            valorationsNum: "Número de valoraciones",
                            studentsNum: "Número de alumnos que han cursado el curso",
                            noBuy: "No puedes volver a comprar un curso que ya has comprado"
                        }
                    }
                }
            },
            ca: {
                translation: {
                    myCourses: {
                        title: "Els meus cursos",
                        noCourses: "No hi ha ningun curs"
                    },
                    browse: {
                        title: "Explorar",
                        apply: "Aplicar filtres",
                        level: {
                            title: "Nivell del curs",
                            basic: "Bàsic",
                            basicDedicated: "Bàsic dedicat",
                            dedicated: "Dedicat",
                            advanced: "Avançat"
                        },
                        lan: {
                            title: "Idioma del curs",
                            cat: "Català",
                            esp: "Espanyol"
                        },
                        progLan: {
                            title: "Llenguatges en el curs",
                        }
                    },
                    account: {
                        title: "Dades personals"
                    },
                    datos: {
                        name: "Nom",
                        surname: "Cognom",
                        secondSurname: "Segon cognom",
                        birthdate: "Data de neixement",
                        send: "Enviar dades"
                    },
                    course: {
                        enter: "Entrar al curs",
                        level: "Nivell",
                        buyNow: "Comprar ara",
                        contentsIndex: "Índex de continguts",
                        presentationVideo: "Vídeo de presentació",

                        screens: {
                            finished: {
                                title: "Enhorabona, has acabat el curso",
                                msg: "Encara no hi ha una nota final per aquest curs. Això significa que pots reclamar un qüestionari per pujar notes per aquells mòduls amb els que no estàs satisfet o pots sol·licitar el diploma i tencar el curs.",
                                diploma: "Sol·licitar diploma",
                                nota: "Sol·licitar pujar nota"
                            },
                            publishCode: {
                                info: "Aquí és on has de pujar el codi que has programat perquè el puguem corregir.",
                                intentsTitle: "Intents realitzats",
                                intentSingular: "intent realitzat",
                                intentPlural: "intents realitzats",
                                noIntents: "Encara no s'han realitzat intents",
                                max: "intents com a màxim",
                                buttonText: "Pujar arxiu",
                                buttonChange: "Canviar arxiu",
                                buttonSend: "Enviar arxiu",
                                noIntents: "Encara no s'ha intentat cap vegada aquesta entrega",
                                lastIntent: "Últim intent"
                            }
                        },

                        tooltip: {
                            valorationsNum: "Número de valoracions",
                            studentsNum: "Número d'alumnes que han cursat el curs",
                            noBuy: "No pots tornar a comprar un curs que ja has comprat"
                        }
                    }
                }
            }
        }
    });

export default i18n;