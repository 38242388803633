import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import "../style/cancelled.css"
import Navbar from "../components/navbar";
import { Button } from "@mui/material";

export default function PaymentCancelled() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    if (state === 1) {
        return <div style={{height:'90%'}}>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <div style={{height:'100%', display:'flex', alignItems:'center'}}>
                <div style={{textAlign:'center', maxWidth:600, margin:'auto'}}>
                    <div className="page-title-t">VAYA</div>
                    <div style={{height:20}}/>
                    <div className="page-subtitle-t">Parece que el pago se ha cancelado o algo ha ido mal. Vuelvelo a intentar y si el error permanece puede contactar con el equipo de soporte.</div>
                    <div style={{height:20}}/>
                    <Button variant="outlined" onClick={() => {
                        if (searchParams.get('course')) {
                            navigate('/course/' + searchParams.get('course'));
                        } else {
                            navigate('/');
                        }
                    }}>Volver al curso</Button>
                </div>
            </div>
        </div>
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}