import Navbar from "../components/navbar";
import LoadingBarApp from "../components/loading";
import AuthProvider from "../components/auth-provider";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { User } from '../implementation/user';
import TextField from '@mui/material/TextField';
import '../style/dashboard-profile.css';
import { Button, OutlinedInput, Tooltip, createTheme } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { ThemeProvider } from "@emotion/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Account() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    /*
      0: Loading
      1: Logged in
    */
    const [state, setCurrentState] = useState(0);
    const [dataState, setDataState] = useState(0);
    const [userr, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [validated, setValidated] = useState(false);
    const [fakeState, setFakeState] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const theme = createTheme({
        palette: {
            primary: {
                main: blueGrey[600]
            },
            secondary: {
                main: "#000"
            }
        }
    });

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getData() {
        try {
            const docRef = await getDoc(doc(db, 'users', userr.uid));
            console.log(docRef.exists());

            if (docRef.exists()) {
                setUserData(User.fromDataBase(docRef));
                setDataState(1);
                setDisabled(true);
            } else {
                setUserData(new User(false, '', '', '', ''));
                setDataState(1);
            }
        } catch(e) {

        }
    }

    if (state === 1) {
        if (dataState === 0) {
            getData();
        }

        if (dataState === 1) {
            function validate() {
                setValidated(true);

                const name = document.getElementById('name-field');
                const surname = document.getElementById('surname-field');
                const secondSurname = document.getElementById('second-surname-field');
                const birthdate = document.getElementById('birthdate-field');

                userData.updateName(name.value);
                userData.updateSurname(surname.value);
                userData.updateSecondSurname(secondSurname.value);
                userData.updateBirthdate(new Date(birthdate.value));

                console.log('UserDataNAme: ' + userData.getName() + " :: " + userData.validateName());

                console.log('Name: ' + name.value);
                console.log('Surname: ' + surname.value);
                console.log('Second surname: ' + secondSurname.value);
                console.log('Date: ' + new Date(birthdate.value) + ' :: ' + userData.validateBirthdate());

                return userData.validateAll();
            }

            async function submitData() {
                if (validate()) {
                    if (userData.getExists()) {
                        await updateDoc(doc(db, 'users', userr.uid), {
                            'name': userData.getName(),
                            'surname': userData.getSurname(),
                            'secondSurname': userData.getSecondSurname(),
                            'birthday': userData.getBirthdate(),
                        });
                    } else {
                        await setDoc(doc(db, 'users', userr.uid), {
                            'name': userData.getName(),
                            'surname': userData.getSurname(),
                            'secondSurname': userData.getSecondSurname(),
                            'birthday': userData.getBirthdate(),
                        });
                    }
                }
                setFakeState(!fakeState);
            }

            function renderPage() {
                console.log();
                return <div>
                    <Navbar userId={userr.uid}/>
                    <div style={{height:'64px'}}/>
                    <div style={{margin:10}}>
                        <h1 className="title">{t('account.title')}</h1>
                    </div>
                    <div style={{margin:'10px 5% 5px 5px'}}>
                        <ThemeProvider theme={theme}>
                            <div>
                                <TextField 
                                    style={{width:'100%', marginTop:5, marginBottom:5}} 
                                    id="name-field" 
                                    label={t("datos.name")} 
                                    variant="outlined" 
                                    color="primary"
                                    defaultValue={userData.getName()}
                                    disabled={disabled}
                                    error={(!userData.validateName() || userData.getName() === '') && validated}
                                    helperText={validated&&(!userData.validateName() || userData.getName() === '')? "Introduzca un nombre real y válido": null}
                                />
                                <TextField 
                                    style={{width:'100%', marginTop:5, marginBottom:5}} 
                                    id="surname-field"
                                    label={t("datos.surname")}
                                    variant="outlined" 
                                    defaultValue={userData.getSurname()} 
                                    disabled={disabled}
                                    error={(!userData.validateSurname() || userData.getSurname() === '') && validated}
                                    helperText={validated&&(!userData.validateSurname() || userData.getSurname() === '')? "Introduzca un apellido real y válido": null}
                                />
                                <TextField
                                    style={{width:'100%', marginTop:5, marginBottom:5}}
                                    id="second-surname-field"
                                    label={t("datos.secondSurname")}
                                    variant="outlined"
                                    defaultValue={userData.getSecondSurname()}
                                    disabled={disabled}
                                    error={(!userData.validateSecondSurname() || userData.getSecondSurname() === '') && validated}
                                    helperText={validated&&(!userData.validateSecondSurname() || userData.getSecondSurname() === '')? "Introduzca un segundo apellido real y válido": null}
                                />
                                <div style={{marginTop:5}}>{t("datos.birthdate")}:</div>
                                <OutlinedInput 
                                    style={{width:'100%', marginBottom:5, color:validated&&!userData.validateBirthdate()?theme.palette.error.main:"black"}} 
                                    type="date" 
                                    id="birthdate-field"
                                    defaultValue={userData.getStringBirthdate()}
                                    disabled={disabled}
                                    error={validated&&!userData.validateBirthdate()}
                                ></OutlinedInput>
                                {disabled
                                    ? <Tooltip title={"Los datos personales sólo pueden configurarse una vez, si desea un cambio o rectificación de estos, pongase en contacto con nosotros."}>
                                        <span>
                                            <Button
                                                color="secondary" 
                                                type="submit" 
                                                onClick={submitData}
                                                disabled
                                            >{t("datos.send")}</Button>
                                        </span>
                                    </Tooltip>
                                    : <Button
                                        color="secondary" 
                                        type="submit" 
                                        onClick={submitData}
                                    >{t("datos.send")}</Button>
                                }
                            </div>
                        </ThemeProvider>
                    </div>
                </div>
            }

            console.log(userData);
            return renderPage();
        }

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <LoadingBarApp/>
        </div>
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
  </AuthProvider>
}