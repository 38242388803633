import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthProvider, { AuthProviderForum } from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import Navbar from "../components/navbar";
import { useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Forum, Respond } from "../implementation/forums";
import { ExpandableSection } from "../components/section";
import { Alert, Breadcrumbs, Divider, Typography } from "@mui/material";
import RespondButton from "../components/respond-btn";

export default function Forums() {
    const navigate = useNavigate();
    const {courseId} = useParams();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);
    const [canSend, setCanSend] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [courseName, setCourseName] = useState(null);
    const [forums, setForums] = useState([]);
    const [viewForum, setViewForum] = useState(null);
    const [therePrincipal, setTherePrincipal] = useState(false);
    const [thereSecondary, setThereSecondary] = useState(false);
    const [fakeState, setFakeState] = useState(false);
    const [sendMsg, setSendMsg] = useState(null);
    const [writingMsg, setWritingMsh] = useState("");

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCanSend(true);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    function handleUserNotPublicUser(user) {
        setUser(user);
        setCurrentState(1);
    }

    async function getCourseName() {
        const courseData = await getDoc(doc(db, 'courses', courseId));

        return courseData.get('title');
    }

    async function getForums() {
        try {
            const forumsData = await getDocs(collection(db, 'courses', courseId, 'forums'));
            console.log(forums);

            var thereIsPrincipal = false;
            var thereIsSecondary = false;

            await Promise.all(forumsData.docs.map(async (forumData, index) => {
                console.log(forumData.data());

                const forum = await Forum.fromDatabase(forumData);
                console.log(forum);

                forums.push(forum);

                if (forum.getType()) {
                    thereIsPrincipal = true;
                }else {
                    thereIsSecondary = true;
                }
            }));

            setFakeState(!fakeState);
            setTherePrincipal(thereIsPrincipal);
            setThereSecondary(thereIsSecondary);
            setCourseName(await getCourseName());
        } catch (e) {
            console.error(e);
        }
    }

    async function getForum() {
        try {
            const forumData = await getDoc(doc(db, 'courses', courseId, 'forums', searchParams.get('forum')));

            console.log(forumData.data());

            const form = await Forum.fromDatabase(forumData);

            setCourseName(await getCourseName());
            setViewForum(form);
        } catch (e) {
            console.error(e);

            setCourseName(await getCourseName());
            setViewForum(false);
        }
    }

    function sendRespond(username) {
        setSendMsg(username);
    }

    function setNewMsg(newMsg) {
        setSendMsg(newMsg);
    }

    function cleanActualInfo() {
        setViewForum(null);
        setSendMsg(null);
    }

    function reload() {
        cleanActualInfo();
        setFakeState(!fakeState);
    }

    if (state === 1) {
        if (searchParams.get('forum') === null) {
            if (forums.length === 0) {
                getForums();

                return <div>
                    <Navbar userId={userr.uid}/>
                    <div style={{height:'64px'}}/>
                    <LoadingBarApp/>
                </div>
            } else {
                if (viewForum !== null)
                    cleanActualInfo();

                return <div>
                    <Navbar userId={userr.uid}/>
                    <div style={{height:'64px'}}/>
                    <div style={{margin:'5px'}}>
                        <Breadcrumbs aria-label="breadcrumb" separator="›">
                            <Typography color="text.primary" style={{fontWeight:'700'}}>Foros de {courseName}</Typography>
                        </Breadcrumbs>
                    </div>
                    <div style={{margin:'5px'}}>
                        <ExpandableSection title="Foros principales">
                            {therePrincipal
                                ? <div style={{margin:'5px'}}>
                                    {forums.map((forum, index) => {
                                    return <div key={index}>
                                        {forum.getType()
                                            ? forum.drawForumInfo(navigate, courseId)
                                            : null
                                        }
                                    </div>;
                                })}
                                </div>
                                : <div style={{margin:'5px'}}>
                                    No hay ningún foro principal.
                                </div>
                            }
                        </ExpandableSection>
                        <div style={{height:'10px'}}/>
                        <ExpandableSection title="Foros secundario">
                            {thereSecondary
                                ? <div style={{margin:'5px'}}>
                                    {forums.map((forum, index) => {
                                    return <div key={index}>
                                        {forum.getType()
                                            ? null
                                            : forum.drawForumInfo(navigate, courseId)
                                        }
                                    </div>;
                                })}
                                </div>
                                : <div style={{margin:'5px'}}>
                                    No hay ningún foro secundario.
                                </div>
                            }
                        </ExpandableSection>
                    </div>
                </div>
            }
        } else {
            if (viewForum === null) {
                getForum();

                return <div key={2}>
                    <Navbar userId={userr.uid}/>
                    <div style={{height:'64px'}}/>
                    <LoadingBarApp/>
                    <div style={{margin:'5px'}}>
                        <Breadcrumbs aria-label="breadcrumb" separator="›">
                            <Link underline="hover" href="/" style={{color:'black'}}>
                                Foros de {courseName}
                            </Link>
                            <Typography color="text.primary" style={{fontWeight:'700'}}>...</Typography>
                        </Breadcrumbs>
                    </div>
                </div>
            } else {
                console.log(viewForum);

                if (viewForum === false) {
                    return <div key={1}>
                        <Navbar userId={userr.uid}/>
                        <div style={{height:'64px'}}/>
                        <div style={{margin:'5px'}}>
                            <Breadcrumbs aria-label="breadcrumb" separator="›">
                                <Link underline="hover" href="/" style={{color:'black'}}>
                                    Foros de {courseName}
                                </Link>
                                <Typography color="text.primary" style={{fontWeight:'700'}}>Inexistente</Typography>
                            </Breadcrumbs>
                        </div>
                        <div style={{margin:'5px', fontSize:'20px', fontWeight:'700'}}>
                            Este foro ya no existe o nunca ha existido.
                        </div>
                    </div>
                } else {
                    return <div key={0}>
                        <Navbar userId={userr.uid}/>
                        <div style={{height:'64px'}}/>
                        <div style={{margin:'5px'}}>
                            <Breadcrumbs aria-label="breadcrumb" separator="›">
                                <Link underline="hover" href="/" style={{color:'black'}}>
                                    Foros de {courseName}
                                </Link>
                                <Typography color="text.primary" style={{fontWeight:'700'}}>{viewForum.getTitle()}</Typography>
                            </Breadcrumbs>
                        </div>
                        <div style={{margin:'5px'}}>
                            <div className="forum-view">
                                <div className="forum-view-title">
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div>
                                            <div>
                                                {viewForum.getTitle()}
                                            </div>
                                            <div style={{height:'10px'}}/>
                                            <div style={{fontSize:'20px', fontWeight:'500'}}>
                                                {viewForum.getWelcomeMsg()}
                                            </div>
                                        </div>
                                        <div style={{marginLeft:'auto'}}>
                                            {viewForum.getNumber()} {viewForum.getNumber() === 1? " mensaje" : " mensajes"}
                                        </div>
                                    </div>
                                </div>
                                <div className="forom-view-chat">
                                    {canSend
                                        ? <div/>
                                        : <div style={{margin:'5px'}}>
                                            <Alert style={{border:'2px solid #ed6c02'}} severity="warning">
                                                No se puede enviar mensajes en el foro si no se ha configurado un perfil público. <span className="options-text" style={{fontSize:'13px'}} onClick={() => {navigate('/dashboard/profile')}}>Hazlo aquí.</span>
                                            </Alert>
                                        </div>
                                    }
                                    {sendMsg !== -1
                                        ? <RespondButton onClick={() => {
                                            setSendMsg(-1);
                                        }} disabled={!canSend}/>
                                        : <div/>
                                    }
                                    {sendMsg === -1
                                        ? <Respond sendMsg={sendMsg} setSendMsg={setNewMsg} courseId={courseId} forumId={viewForum.getId()} reload={reload}/>
                                        : <div/>
                                    }
                                    <div style={{height:'10px'}}/>
                                    {viewForum.getMessages().map((message, index) => {
                                        return message.drawMessage(index, true, sendRespond, sendMsg, setNewMsg, courseId, viewForum.getId(), reload, canSend);
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
        }
    }
    
    return <AuthProviderForum
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
      onUserNotPublicProfile={handleUserNotPublicUser}>
        <LoadingBarApp/>
    </AuthProviderForum>
}