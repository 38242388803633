import { collection, getDocs, query, where } from "firebase/firestore"
import { useState } from "react";
import { db } from "../firebase/firebase";
import "../style/my-courses.css";
import CourseCard from "./course-card";
import CircularProgressApp from "./circular-loading";
import { Course } from "../implementation/course";
import CourseViwerCard from "./course-viwer";
import { useTranslation } from "react-i18next";

export default function MyCourses({myuser}) {
    const { t } = useTranslation();

    const [state, setCurrentState] = useState(0);
    const [courses, setCourses] = useState([]);

    async function getData() {
        const q = query(collection(db, 'courses'), where('users', 'array-contains', myuser.uid));

        try {
            const newQuerySnapshot = await getDocs(q);

            if (newQuerySnapshot.empty) {
                console.log("Is empty");
                setCurrentState(1);
            } else {
                newQuerySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    
                    courses.push(Course.fromDataBase(doc));
                    console.log(courses.length);
                });
                setCurrentState(2);
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    if (state === 0) {
        getData();
    }

    if (state === 1) {
        return <MyCoursesTitle>{t('myCourses.noCourses')}</MyCoursesTitle>
    }

    if (state === 2) {
        console.log(courses.length);
        return <MyCoursesTitle>
            <div className="courses-view">
                {courses.map(item => {
                    return (<div key={item.id}><CourseViwerCard course={item} progress={true} user={myuser}/></div>);
                })}
            </div>
        </MyCoursesTitle>
    }

    return <MyCoursesTitle><CircularProgressApp/></MyCoursesTitle>
}

export function MyCoursesTitle({children}) {
    const { t } = useTranslation();

    return <div className="my-courses">
        <div className="title">{t('myCourses.title')}</div>
        <div>{children}</div>
    </div>
}