import { Divider } from "@mui/material";
import Navbar from "../components/navbar";
import PageBoxButton from "../components/page-box";
import { useNavigate } from "react-router-dom";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SourceIcon from '@mui/icons-material/Source';
import AuthProvider from "../components/auth-provider";
import { useState } from "react";
import LoadingBarApp from "../components/loading";
import { Settings } from "@mui/icons-material";

export default function Dashboard() {
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    if (state === 1) {
        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <div style={{margin:'5px'}}>
                <h1 className="title">
                    Panel de estudiante
                </h1>
                <Divider/>
                <div style={{display:'flex', flexWrap:'wrap', marginTop:'5px'}}>
                    <PageBoxButton onClick={() => {navigate("/dashboard/account")}} icon={<SourceIcon style={{color:"white"}}/>} title="Datos personales" description="Revisa tus datos personales o solicita el cambio de estos."/>
                    <PageBoxButton onClick={() => {navigate('/dashboard/profile')}} icon={<AccountCircleIcon style={{color:"white"}}/>} title="Perfil público" description="Revisa y configura los datos público que verá otra gente cuando publiques un comentario."/>
                    <PageBoxButton icon={<SchoolIcon style={{color:"white"}}/>} title="Expediente" description="Revisa todos los cursos que ya has cursado o estás cursando."/>
                    <PageBoxButton onClick={() => {navigate('/tickets')}} icon={<ConfirmationNumberIcon style={{color:"white"}}/>} title="Tickets" description="Revisa todos los tickets creados o crea uno nuevo."/>
                    <PageBoxButton onClick={() => {window.open('https://bitmasters.es/dashboard/account')}} icon={<Settings style={{color:"white"}}/>} title="Información de la cuenta" description="Revisa tu información de facturación y datos de tu cuenta en BitMasters."/>
                </div>
            </div>
        </div>;
    }

    return <AuthProvider
    onUserLoggedIn={handleOnUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}