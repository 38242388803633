import { onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { auth, userExists } from "../firebase/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import { Alert, Button, CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, ThemeProvider, createTheme } from "@mui/material";
import '../style/login.css';
import logo from "../assets/images/LogoBM_Full.png"
import { useMediaQuery } from "react-responsive";

export default function RecoverPassword() {
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [validated, setValidated] = useState(false);
    const [mail, setMail] = useState('');
    const [loading, setLoading] = useState(false);

    const [noti, setNoti] = useState(['', '']);

    const isLaptop = useMediaQuery({
        query: '(min-width: 630px)'
    });

    const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    function handleOnUserLoggedIn(user) {
        navigate("/");
    }

    function handleOnUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleOnUserNotLoggedIn() {
        setCurrentState(4);
    }

    function validateMail(mailStr) {
        if (mailStr) {
            return mailRegex.test(mailStr);
        } else {
            return mailRegex.test(mail);
        }
    }

    const handleClick = async () => {
        setMail(document.getElementById('mail-field').value);
        setValidated(true);

        if (validateMail(document.getElementById('mail-field').value)) {
            setLoading(true);
            try {
                const response = await sendPasswordResetEmail(auth, document.getElementById('mail-field').value);
                setNoti(['Se ha enviado el enlace de recuperación', 'success']);
            } catch (e) {
                console.log(e);
                setNoti(['No hemos podido enviar el enlace de recuperación', 'error'])
            }
            setLoading(false);
        }
    }


    if (state === 4) {
        return <div style={{height:'100%', marginLeft: '5px', marginRight:'5px'}}>
            {isLaptop? <div style={{display:'flex'}}>
                    <img className="page-logo" src={logo}/> 
                </div> : <div></div>}
            <div style={{display:'flex'}}>
                <div className="login-form-card">
                    <div style={{display:'flex'}}>
                        <div className="login-title">
                            RECUPERAR CONTRASEÑA
                        </div>
                    </div>
                    <Divider sx={{margin:1}}/>
                    {noti[0]!==''? <Alert style={{border: noti[1]==='error'? '2px solid #d32f2f' : '2px solid #2e7d32', margin: '5px'}} severity={noti[1]}>{noti[0]}</Alert> : <div></div>}
                    <div className="form-login-card">
                        <div style={{margin:'20px 5px', textAlign:'justify'}}>
                            Para poder recuperar la contraseña debe introducir su correo electrónico, si el correo está registrado y corresponde a alguno de nuestros usurios le mandaremos un correo electrónico con un enlace de recuperación.
                        </div>
                        <TextField
                            type="mail"
                            style={{ width:'100%', marginTop:5, marginBottom:5}}
                            id="mail-field"
                            label="E-Mail"
                            variant="outlined"
                            defaultValue={''}
                            error={!validateMail() && validated}
                            helperText={validated&&!validateMail()? "Introduzca un correo electrónico que sea válido": null}
                        />
                        <Button sx={{width:'100%', marginTop:'5px'}} disabled={loading} onClick={handleClick} variant="outlined">{!loading? "Recuperar" : <CircularProgress/>}</Button>
                    </div>
                    <Divider sx={{margin: 1}}/>
                    <div style={{textAlign: 'center', margin: '5px'}}>
                        <div className="options-text">¿Te acuerdas de la contraseña? <span className="options-text" onClick={() => {navigate('/login')}}>Inicia sesión</span></div>
                        <div style={{height:'10px'}}/>
                        <div className="options-text">¿Aún no tienes una cuenta? <span className="options-text">Registrate</span></div>
                    </div>
                </div>
            </div>
        </div>;
    }

    return <AuthProvider 
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotLoggedIn={handleOnUserNotLoggedIn}
      onUserNotRegistered={handleOnUserNotRegistered}>
        <LoadingBarApp/>
    </AuthProvider>
}