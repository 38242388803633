import { Divider, Tooltip } from "@mui/material";
import UserAvatar from "./user-avatar";


export default function MessageView({ message, username, tooltil, right = false, onClick }) {
    return <div style={{marginTop:'10px', marginBottom:'10px', borderTop:'1px solid #333', borderBottom:'1px solid #333'}}>
        <div style={{display:'flex', alignItems:'center', flexFlow:right ? 'row-reverse' : 'row'}}>
            <UserAvatar username={username} sx={{margin:'5px'}}/>
            <Tooltip title={tooltil}>
                <div style={{fontSize:'22px', fontWeight:'500'}} className="username-message" onClick={onClick}>
                    {username}
                </div>
            </Tooltip>
        </div>
        <Divider/>
        <div style={{height:'5px'}}/>
        <div style={{marginLeft:'40px', marginRight: '40px', textAlign:right ? 'end' : null}}>
            {message}
        </div>
        <div style={{height:'10px'}}/>
    </div>
}