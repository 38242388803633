import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import { and, collection,  getDocs, or, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import '../style/browse.css';
import { FilterLan, FilterLenguajes, FilterLevel } from "../components/filters";
import { Box, Button, Drawer } from "@mui/material";
import { Course } from "../implementation/course";
import CourseViwerCard from "../components/course-viwer";
import Divider from '@mui/material/Divider';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useTranslation } from "react-i18next";

export default function BrowseCourses() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [state, setCurrentState] = useState(0);
    /*
        0: Loading
        1: Getting userData
        2: All loaded
    */
    const [courses, setCourses] = useState([]);
    const [userr, setUser] = useState(null);

    const [filters, setFilters] = useState({
        "level": [false, {
            "Básico": false,
            "Básico dedicado": false,
            "Dedicado": false,
            "Avanzado": false
        }],
        "lan": [false, {
            "Español": false,
            "Catalán": false
        }],
        "lenguajes": [false, {
            "Python": false,
            "Arduino": false,
            "HTML": false
        }]
    });

    const [oldFilters, setOldFilters] = useState({
        "level": [false, {
            "Básico": false,
            "Básico dedicado": false,
            "Dedicado": false,
            "Avanzado": false
        }],
        "lan": [false, {
            "Español": false,
            "Catalán": false
        }],
        "lenguajes": [false, {
            "Python": false,
            "Arduino": false,
            "HTML": false
        }]
    });

    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    function diferenceInCheckBox(arrayList1, arrayList2) {
        var differences = false;

        console.log("ArrayList1: ");
        console.log(arrayList1);

        console.log("ArrayList2: ");
        console.log(arrayList2);

        for (var item in arrayList1) {
            console.log("Item in diffenreces: " + item + " => " + arrayList1[item] + " :: " + arrayList2[item]);
            if (arrayList1[item] !== arrayList2[item]) {
                console.log("Differences in: " + item);
                differences=true;
                break;
            }
        }
        
        return differences;
    }

    function diferentFilters() {
        console.log("Searching for differences: ");
        console.log(filters);
        console.log(oldFilters);

        var diference = false;
        for (var filter in filters) {
            if (filters[filter][0] !== oldFilters[filter][0] || (filters[filter][0] && diferenceInCheckBox(filters[filter][1], oldFilters[filter][1]))) {
                diference = true;
                break;
            }
        }

        return diference;
    }

    const closeDrawer = () => {
        setOpen(false);

        if (diferentFilters()) {
            setLoading(true);
        }
    }

    const DrawerList = (
        <Box sx={{width:220}} role="presentation" >
        <Button onClick={closeDrawer}>{t('browse.apply')}</Button>
        <FilterLevel sx={{padding:'15px'}} defaultValue={filters['level']} passFilters={updateLevel}/>
        <FilterLan sx={{padding:'15px'}} defaultValue={filters['lan']} passFilters={updateLan}/>
        <FilterLenguajes sx={{padding:'15px'}} defaultValue={filters['lenguajes']} passFilters={updateLenguaje}/>
        <Divider />
        </Box>
    );

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(2);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    if (state === 1) {
        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <LoadingBarApp/>
        </div>
    }

    function updateLevel(newFilters) {
        filters['level'] = newFilters;

        console.log(newFilters);
    }

    function updateLan(newFilters) {
        filters['lan'] = newFilters;

        console.log(newFilters);
    }

    function updateLenguaje(newFilters) {
        filters['lenguajes'] = newFilters;

        console.log(newFilters);
    }

    function copyFilters() {
        for (var filter in filters) {
            oldFilters[filter][0] = filters[filter][0];
            
            for (var item in filters[filter][1]) {
                oldFilters[filter][1][item] = filters[filter][1][item];
            }
        }
    }

    function moreThanOne(arrayList1) {
        var truesFound = 0;

        for (var item in arrayList1) {
            if (arrayList1[item]) {
                truesFound++;
            }
        }

        return truesFound;
    }

    function levelStringToIn(levelStr) {
        switch (levelStr) {
            case "Básico":
                return 1;
            case "Básico dedicado":
                return 2;
            case "Dedicado":
                return 3;
            case "Avanzado":
                return 4;
            default:
                return 1;
        }
    }

    function idiomaStringToString(idiomaStr) {
        switch (idiomaStr) {
            case "Español":
                return "ESP";
            case "Catalán":
                return "CAT";
            default:
                return "ESP";
        }
    }

    function lenguajeStringToString(lenguajeStr) {
        switch (lenguajeStr) {
            case "Python":
                return "python";
            case "Arduino":
                return "arduino";
            case "HTML":
                return "html";
            default:
                return "python";
        }
    }

    async function search() {
        copyFilters();

        try {
            var filtersQuery = [];

            if(filters['level'][0]){
                const totalTrues = moreThanOne(filters['level'][1])
                console.log("Total trues: " + totalTrues);
                if (totalTrues === 1) {
                    for (var item in filters['level'][1]) {
                        if (filters['level'][1][item]) {
                            filtersQuery.push(where('level', "==", levelStringToIn(item)));
                        }
                    }
                } else if (totalTrues > 1) {
                    var innerFilters = [];
                    for (var item in filters['level'][1]) {
                        if (filters['level'][1][item]) {
                            innerFilters.push(where('level', "==", levelStringToIn(item)));
                        }
                    }
                    console.log("Or statemanet: ");
                    console.log(or(...innerFilters));
                    filtersQuery.push(or(
                        ...innerFilters
                    ));
                }
            }

            if(filters['lan'][0]){
                const totalTrues = moreThanOne(filters['lan'][1])
                console.log("Total trues: " + totalTrues);
                if (totalTrues === 1) {
                    for (var item in filters['lan'][1]) {
                        if (filters['lan'][1][item]) {
                            filtersQuery.push(where('idioma', "==", idiomaStringToString(item)));
                        }
                    }
                } else if (totalTrues > 1) {
                    var innerFilters = [];
                    for (var item in filters['lan'][1]) {
                        if (filters['lan'][1][item]) {
                            innerFilters.push(where('idioma', "==", idiomaStringToString(item)));
                        }
                    }
                    console.log("Or statemanet: ");
                    console.log(or(...innerFilters));
                    filtersQuery.push(or(
                        ...innerFilters
                    ));
                }
            }

            if(filters['lenguajes'][0]){
                const totalTrues = moreThanOne(filters['lenguajes'][1])
                console.log("Total trues: " + totalTrues);
                if (totalTrues === 1) {
                    for (var item in filters['lenguajes'][1]) {
                        if (filters['lenguajes'][1][item]) {
                            filtersQuery.push(where('lenguajes', "array-contains", lenguajeStringToString(item)));
                        }
                    }
                } else if (totalTrues > 1) {
                    var innerFilters = [];
                    for (var item in filters['lenguajes'][1]) {
                        if (filters['lenguajes'][1][item]) {
                            innerFilters.push(where('lenguajes', "array-contains", lenguajeStringToString(item)));
                        }
                    }
                    console.log("Or statemanet: ");
                    console.log(or(...innerFilters));
                    filtersQuery.push(or(
                        ...innerFilters
                    ));
                }
            }

            const q = query(collection(db, 'courses'), and(
                where('active', '==', true),
                ...filtersQuery
            ));

            console.log(q);

            const querySnapshot = await getDocs(q);
            console.log("Data searched: " + querySnapshot.docs.length);
            const searchResults = [];
            querySnapshot.forEach((doc) => {
                console.log(doc.id + " :: " + doc.data() + " :: " + doc.get('capitulos'));
                searchResults.push(Course.fromDataBase(doc));
                console.log(Course.fromDataBase(doc));
            });

            setCourses(searchResults);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    function filtersCount() {
        var count = 0;

        for (var filter in filters) {
            console.log(filter);
            if (filters[filter][0]) {
                count++;
            }
        }

        return count;
    }
    
    if (state === 2) {
        if (loading) {
            console.log("Searching");
            search();
        }

        console.log("Courses lenght: " + courses.length);

        return <div style={{height:'100%'}}>
            <Navbar userId={userr.uid} drawer={true} toggleDrawer={toggleDrawer} drawerIcon={<FilterAltOutlinedIcon fontSize="large" color="primary"/>} drawerIconTooltip={"Filtros"} badgeCount={filtersCount()}/>
            <Drawer open={open} onClose={closeDrawer}>{DrawerList}</Drawer>
            <div style={{height:'64px'}}/>
            <div className="screen-body">
                <div className="main">
                    {loading? <div style={{display:'flex', flexFlow:'row flow'}}><LoadingBarApp/></div> : courses.length === 0? <div className="no-results">
                        No se han encontrado resultados
                        <div className="small-info">Modifique algunos filtros para poder encontrar resultados</div>
                    </div> : <div className="results">{courses.map(item => {
                        console.log(item);
                        return (<div key={item.getId()}><CourseViwerCard course={item} progress={false} user={userr}/></div>);
                    })}</div>}
                </div>
            </div>
        </div>
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}