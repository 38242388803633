import { Box, createTheme, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from "react";

export default function CircularProgressApp({val = 70}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);

    return <div style={{margin:'5px', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress className="nota-viwer" variant="determinate" value={val} style={{width: windowWidth*0.4, height: windowWidth*0.4, color: (val<60)? '#f75757' : (val<70)? '#f79457' : (val<90)? '#f7d757' : '#71f757'}}/>
            <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <div style={{fontSize:'40px', fontWeight:'700'}}>
                    {`${val}%`}
                </div>
            </Box>
        </Box>
    </div>
}