import { Link, useNavigate } from 'react-router-dom'
import '../style/navbar.css'
import logo from '../assets/images/LogoTextBM.png';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Badge, Button, Divider, Drawer, List, ListItem, ThemeProvider, Tooltip, createTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db, functions } from '../firebase/firebase';
import { PaymentsOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import LoadingBarApp from './loading';
import { Course } from '../implementation/course';
import { RoundButton } from './customUi';
import { httpsCallable } from 'firebase/functions';

export default function Navbar({ drawer=false, toggleDrawer, drawerIcon, badgeCount, drawerIconTooltip, userId, handleOnCart=(cart) => {console.log(cart)} }) {
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [carrito, setCarrito] = useState(null);
    const [carritoObj, setCarritoObj] = useState(null);
    const [cartOpen, setCartOpen] = useState(false);
    const [fakeState, setFakeState] = useState(false);

    const theme = createTheme({
      palette: {
        primary: {
          main: '#36454f'
        }
      }
    });

    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function getCart() {
      const userDataRef = doc(db, 'users', userId);
      const userData = await getDoc(userDataRef);

      if (userData.get('carrito')) {
        setCarrito(userData.get('carrito'));
        handleOnCart(userData.get('carrito'));
      } else {
        setCarrito([]);
        handleOnCart([]);
      }

      const updateCart = onSnapshot(userDataRef, (docData) => {
        if (docData.get('carrito')) {
          setCarritoObj(null);
          setCarrito(docData.get('carrito'));
          handleOnCart(docData.get('carrito'));
        } else {
          setCarritoObj(null);
          setCarrito([]);
          handleOnCart([]);
        }
      });
    }

    async function getCartData() {
      console.log("Carrito:");
      console.log(carrito);
      const newCarrito = {};
      
      await Promise.all(carrito.map(async cartObj => {
        const docData = await getDoc(doc(db, 'courses', cartObj['id']));
        const courseData = Course.fromDataBase(docData);

        const priceRef = await getDocs(query(collection(db, 'products', courseData.getId(), 'prices'), where('active', '==', true)));
                courseData.updatePrice(priceRef.docs[0].get('unit_amount'));
                if (priceRef.docs[1])
                    courseData.updateSecondPrice(priceRef.docs[1].get('unit_amount'));

        newCarrito[cartObj['id']] = courseData;
      }));

      setCarritoObj(newCarrito);
    }

    function calcTotalPrice() {
      var totalPrice = 0;

      if (carritoObj !== null) {
        carrito.map(cartObj => {
          if (carritoObj[cartObj['id']].getSecondPrice()) {
            totalPrice += carritoObj[cartObj['id']].getSecondPrice();
          } else {
            totalPrice += carritoObj[cartObj['id']].getPrice();
          }
        });
      }

      return totalPrice;
    }

    if (carrito !== null && carritoObj === null) {
      getCartData();
    }

    if (carrito === null)
      getCart()

    return <ul className="navbar">
        {drawer? <Tooltip title={drawerIconTooltip}><Button style={{margin:2, padding:2, minWidth:0}} onClick={toggleDrawer(true)}><ThemeProvider theme={theme}><Badge badgeContent={badgeCount} color='primary'>{drawerIcon}</Badge></ThemeProvider></Button></Tooltip> : <div></div>}
        <div><Link className='navbar' to="/"><div className='button' id='no-hover'>
            <img className='logo' src={logo} alt='Logo'/>
        </div></Link></div>
        <div id='right'>
          <ThemeProvider theme={theme}>
            <Button onClick={() => {
              i18n.changeLanguage(i18n.resolvedLanguage === 'es'
                ? "ca"
                : "es");
            }}>
              {i18n.resolvedLanguage === 'es'
                ? "Català"
                : "Español"
              }
            </Button>
          </ThemeProvider>
          <IconButton
            size='large'
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            style={{color:"#36454f", padding:3,}}
            className='button'
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {navigate("/dashboard")}}>Panel de estudiante</MenuItem>
            <MenuItem onClick={handleClose}>Contacto</MenuItem>
            <MenuItem onClick={() => {navigate("/logout");}}>Cerrar sesión</MenuItem>
          </Menu>
          {carrito!==null && carrito.length !==0
            ? <ThemeProvider theme={createTheme({
              palette: {
                  primary: {
                      main: "#36454f"
                  }
              }
            })}>
              <Badge badgeContent={carrito.length} max={9} color="primary" style={{marginRight:'15px'}}>
                <IconButton onClick={() => {setCartOpen(true)}}>
                  <ShoppingCartOutlined color='primary'/>
                </IconButton>
              </Badge>
            </ThemeProvider>
            : null
          }
          <Drawer
            anchor="right"
            open={cartOpen}
            onClose={() => {setCartOpen(false)}}
          >
            {carrito !== null
              ? <List className='cart-drawer'>
                {carrito.map((cartObj, index) => {
                  return <ListItem key={index}>
                    {carritoObj === null || (carritoObj!==null && carritoObj[cartObj['id']] === null)
                      ? <LoadingBarApp/>
                      : <div>{carritoObj[cartObj['id']].drawCartCard(() => {
                        console.log(carrito.splice(carrito.indexOf(cartObj['id'], 1)));
                        const newCarrito = carrito.splice(carrito.indexOf(cartObj['id']), 1);
                        updateDoc(doc(db, 'users', userId), {
                          "carrito": newCarrito
                        });
                      })}</div>
                    }
                  </ListItem>
                })}
                <div style={{height:'5px'}}/>
                <div style={{display:'flex', margin: '5px 15px', fontSize:'24px', fontWeight:'700', fontFamily:'Ubuntu'}}>
                  <div>
                    Total:
                  </div>
                  <div style={{marginLeft:'auto'}}>
                    {(calcTotalPrice()/100).toFixed(2)}€
                  </div>
                </div>
                <div style={{margin:"0px 10px", marginTop:'auto'}}>
                  <ThemeProvider theme={createTheme({
                    palette: {
                        primary: {
                            main: "#36454f"
                        }
                    }
                  })}>
                    <RoundButton text="Revisar y pagar" endIcon={<PaymentsOutlined/>} onClick={() => {
                      const payCart = httpsCallable(functions, 'payCart');
                      payCart().then((result) => {
                        console.log(result.data);

                        const waitPaymentUrl = onSnapshot(doc(db, 'customers', userId, 'checkout_sessions', result.data), (doc) => {
                          if (doc.get('url')) {
                              console.log(doc.get('url'));
                              window.location.href = doc.get('url');
                          }
                        });
                      });
                    }}/>
                  </ThemeProvider>
                </div>
              </List>
              : <div className='cart-drawer'/>
            }
          </Drawer>
        </div>
    </ul>
}