import { getDownloadURL, getMetadata } from "firebase/storage";
import { useState } from "react";
import LoadingBarApp from "./loading";
import { Divider } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export function DocDownload({ doc }) {
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [type, setType] = useState(null);
    const [name, setName] = useState(null);

    async function getDownloadURLDoc() {
        const url = await getDownloadURL(doc);
        console.log(url);
        const metadata = await getMetadata(doc);
        console.log(metadata.name);
        console.log(metadata.contentType);
        setType(metadata.contentType);
        setName(metadata.name);
        setDownloadUrl(url);
    }

    if (downloadUrl === null) {
        getDownloadURLDoc();
    }

    return <div className='download-file'>
        {downloadUrl === null? <div style={{width:'150px', height:'75px'}}>
            <LoadingBarApp/>
        </div> : <a style={{color:'#333'}} href={downloadUrl} target="_blank">
            {type === "application/pdf"? <PictureAsPdfIcon sx={{ fontSize: 80 }}/> : <div></div>}
            <Divider/>
            <div className='doc-name'>
                {name}
            </div>
        </a>}
    </div>
}