import ReactPlayer from "react-player";
import '../style/video-player.css';

export default function VideoPlayerApp({url}) {
    return <ReactPlayer
        className="player"
        url={url}
        width="-webkit-fill-available"
        height="-webkit-fill-available"
        playing={false}
        muted={false}
        
        controls
    />
}