import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";

export default function QuestionAnswers({ step, index, question, saveAnswer }) {

    const handleRadioChange = (event) => {
        console.log(index + " : " + (event.target).value);
        saveAnswer(index, (event.target).value);
    };

    function intToLetter(number) {
        switch(number) {
            case 0:
                return 'a';
            case 1:
                return 'b';
            case 2:
                return 'c';
            case 3:
                return 'd';
        }
    }

    var respondNum = -1;
    return <FormControl>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={intToLetter(step.getContent().getCurrentCuestgionario().getQuestionAt(index).getRespond())}
            name="radio-buttons-group"
            onChange={handleRadioChange}
        >
            {question.getAnswers().map(answer => {
                respondNum++;
                return <FormControlLabel key={intToLetter(respondNum)} value={intToLetter(respondNum)} control={<Radio />} label={intToLetter(respondNum) + ". " + answer}/>;
            })}
        </RadioGroup>
    </FormControl>
}