import { useState } from 'react';
import AuthProvider from '../components/auth-provider';
import LoadingBarApp from '../components/loading';
import '../style/dashboard-profile.css';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar';
import { Button, CircularProgress, createTheme, Divider, TextField, ThemeProvider } from '@mui/material';
import { PublicUser } from '../implementation/publicUser';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { blueGrey } from '@mui/material/colors';

export default function DashboardProfile() {
    const navigate = useNavigate();
    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [validated, setValidated] = useState(false);
    const [fakeState, setFakeState] = useState(false);
    const [loading, setLoading] = useState(false);

    const theme = createTheme({
        palette: {
            primary: {
                main: blueGrey[600]
            },
            secondary: {
                main: "#000"
            }
        }
    });

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getUserData() {
        //publicUsers
        try {
            const docRef =  await getDoc(doc(db, 'publicUsers', userr.uid));
            setUserData(PublicUser.fromDatabase(docRef));
        } catch (e) {
            console.error(e);
            setUserData(new PublicUser('', ''));
        }
    }

    function validate() {
        if (!validated)
            setValidated(true);

        const name = document.getElementById('name-field');
        const description = document.getElementById('surname-field');

        userData.updateName(name.value);
        userData.updateDescription(description.value);

        return userData.validateAll();
    }

    async function submitData() {
        setFakeState(!fakeState);

        if (validate()) {
            console.log("Sending data");

            setLoading(true);
            await setDoc(doc(db, 'publicUsers', userr.uid), userData.toFirebase());
            setLoading(false);
        }
    }

    if (state === 1) {
        if (userData === null) {
            getUserData();
        }

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <div style={{margin:'5px'}}>
                <h1 className='title' style={{marginBottom:'5px'}}>
                    Perfil público
                </h1>
                <Divider/>
                <div style={{marginTop:'5px'}}>
                    {userData !== null
                        ? <div>
                            <ThemeProvider theme={theme}>
                                <TextField
                                    style={{width:'100%', marginTop:5, marginBottom:5}} 
                                    id="name-field" 
                                    label="Nombre de usuario" 
                                    variant="outlined" 
                                    color="primary"
                                    defaultValue={userData.getName()}
                                    error={(!userData.validateName()) && validated}
                                    helperText={validated&&(!userData.validateName())? <span>
                                        <div>
                                            Introduzca un nombre de usuario que cumpla con:
                                            <ol>
                                                <li>Puede contener letras en minúsculas o mayúsculas.</li>
                                                <li>Puede contener números.</li>
                                                <li>No puede contener espacios pero si guiones como _ o -.</li>
                                            </ol>
                                        </div>
                                    </span>: null}
                                />
                                <TextField 
                                    style={{width:'100%', marginTop:5, marginBottom:5}} 
                                    id="surname-field"
                                    label="Descripción" 
                                    variant="outlined" 
                                    defaultValue={userData.getDescription()}
                                    error={(!userData.validateDescription()) && validated}
                                    helperText={validated&&(!userData.validateDescription())? "La descripción no puede estar vacia.": null}
                                />
                                <div style={{display:'flex', paddingTop:'5px'}}>
                                    <Button
                                            color="secondary" 
                                            type="submit"
                                            variant='outlined'
                                            onClick={submitData}
                                            disabled={loading}
                                        >{loading
                                            ? <CircularProgress/>
                                            : "Actualizar"
                                        }
                                    </Button>
                                    <div style={{width:'5px'}}/>
                                    <Button
                                            color="secondary" 
                                            type="submit"
                                            variant='outlined'
                                            onClick={() => {
                                                navigate('/u/'+userr.uid);
                                            }}
                                            disabled={loading}
                                    >Previsualizar perfil</Button>
                                </div>
                            </ThemeProvider>
                        </div>
                        : <LoadingBarApp/>
                    }
                </div>
            </div>
        </div>
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}