import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { PublicUser } from "./publicUser";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import GradeIcon from '@mui/icons-material/Grade';

export class Ticket {
    constructor (title, messages, state, users, type, created, id) {
        this.title = title;
        this.messages = messages;
        this.state = state;
        this.users = users;
        this.type = type;
        this.created = created;
        this.id = id;
    }

    static async fromDatabase(docData, state) {
        const newMessages = [];
        const newUsers = {};

        await Promise.all(docData.get('messages').map(async message => {
            console.log(message);
            newMessages.push(new Message(message['user'], message['message']));

            if (message['user'] !== 'admin' && !newUsers[message['user']]) {
                const userData = await getDoc(doc(db, 'publicUsers', message['user']));

                newUsers[message['user']] = PublicUser.fromDatabase(userData);
            }
        }));

        return new Ticket(docData.get('title'), newMessages, state, newUsers, docData.get('type'), docData.get('created'), docData.id);
    }

    getTitle() {
        return this.title;
    }

    getCreated() {
        return this.created;
    }

    getMessages() {
        return this.messages;
    }

    addMessage(message) {
        this.messages.push(message);
    }

    getId() {
        return this.id;
    }

    getType() {
        return this.type;
    }

    getUsers() {
        return this.users;
    }

    getState() {
        return this.state;
    }

    getTicketIcon() {
        switch(this.type) {
            case 0:
                return <GradeIcon/>;
        }
    }

    getTipeText() {
        switch(this.type) {
            case 0:
                return "Subir nota";
        }
    }
}

export class Message {
    constructor (user, message) {
        this.user = user;
        this.message = message;
    }

    getMessage() {
        return this.message;
    }

    getUser() {
        return this.user;
    }
}