import { useState } from "react";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Ticket } from "../implementation/ticket";
import { createTheme, Divider, Fab, SpeedDial, SpeedDialAction, ThemeProvider } from "@mui/material";
import GradeIcon from '@mui/icons-material/Grade';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import '../style/tickets.css';

export default function TicketPage() {
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [createdTickets, setCreatedTickets] = useState([]);
    const [disputedTickets, setDisputedTickets] = useState([]);
    const [finishedTickets, setFinishedTickets] = useState([]);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getTickets() {
        const newCreatedTickets = [];

        const createdTicketsDocs = await getDocs(collection(db, 'users', userr.uid, 'createdTickets'));
        await Promise.all(createdTicketsDocs.docs.map(async (createdTicket) => {
            newCreatedTickets.push(await Ticket.fromDatabase(createdTicket, 0));
            console.log(newCreatedTickets);
        }));

        const newDisputtedTickets = [];

        const disputtedTicketsDocs = await getDocs(collection(db, 'users', userr.uid, 'disputtedTickets'));
        await Promise.all(disputtedTicketsDocs.docs.map(async (disputedTicket) => {
            newDisputtedTickets.push(await Ticket.fromDatabase(disputedTicket, 1));
            console.log(newDisputtedTickets);
        }));

        const newFinishedTickets = [];

        const finishedTicketsDocs = await getDocs(collection(db, 'users', userr.uid, 'finishedTickets'));
        await Promise.all(finishedTicketsDocs.docs.map(async (finishedTicket) => {
            newFinishedTickets.push(await Ticket.fromDatabase(finishedTicket, 2));
            console.log(newFinishedTickets);
        }));

        setLoaded(true);
        setCreatedTickets(newCreatedTickets);
        setDisputedTickets(newDisputtedTickets);
        setFinishedTickets(newFinishedTickets);
    }

    if (state === 1) {
        if (loaded) {
            return <div>
                <ThemeProvider theme={createTheme({
                    palette: {
                        primary: {
                            main: '#708090'
                        }
                    }
                })}>
                    <SpeedDial sx={{position: 'absolute', bottom: 16, right: 16,}} icon={<SpeedDialIcon/>} ariaLabel="SpeedDial basic example">
                        <SpeedDialAction
                            onClick={() => {
                                navigate('/createTicket?type=0');
                            }}
                            icon={<GradeIcon/>}
                            tooltipTitle="Subir nota"
                        />
                    </SpeedDial>
                </ThemeProvider>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    <h1 className="title">
                        Tickets
                    </h1>
                    <Divider/>
                    <div style={{paddingTop:'5px'}}>
                        <div className="tickets-place">
                            <div className="tickets-title">
                                Tickets pendiente de revisión
                            </div>
                            <div className="tickets-view">
                                {createdTickets.length !== 0
                                    ?createdTickets.map((ticket, index) => {
                                        return <div key={index}>
                                            <div key={index} className="ticket-view" onClick={() => {
                                                navigate('/ticket/'+ticket.getId()+"?type="+ticket.getState());
                                            }}>
                                                {ticket.getTicketIcon()}
                                                <TciketInfoSeparetor key={0}/>
                                                <div className="date-view">
                                                    {ticket.getCreated().toDate().toLocaleString("es-ES")}
                                                </div>
                                                <TciketInfoSeparetor key={1}/>
                                                <div className="title-view">
                                                    {ticket.getTitle()}
                                                </div>
                                                <TciketInfoSeparetor key={2}/>
                                                <div className="message-view">
                                                    {ticket.getMessages()[ticket.getMessages().length-1].getMessage()}
                                                </div>
                                            </div>
                                            <Divider/>
                                        </div>})
                                    : <div style={{padding:'5px'}}>
                                        Aún no hay ningún ticket creado
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{height:'20px'}}/>
                        <div className="tickets-place">
                            <div className="tickets-title">
                                Tickets en curso
                            </div>
                            <div className="tickets-view">
                                {disputedTickets.length !== 0
                                    ?disputedTickets.map((ticket, index) => {
                                        return <div key={index} onClick={() => {
                                            navigate('/ticket/'+ticket.getId()+"?type="+ticket.getState());
                                        }}>
                                            <div key={index} className="ticket-view">
                                                {ticket.getTicketIcon()}
                                                <TciketInfoSeparetor key={0}/>
                                                <div className="date-view">
                                                    {ticket.getCreated().toDate().toLocaleString("es-ES")}
                                                </div>
                                                <TciketInfoSeparetor key={1}/>
                                                <div className="title-view">
                                                    {ticket.getTitle()}
                                                </div>
                                                <TciketInfoSeparetor key={2}/>
                                                <div className="message-view">
                                                    {ticket.getMessages()[ticket.getMessages().length-1].getMessage()}
                                                </div>
                                            </div>
                                            <Divider/>
                                        </div>})
                                    : <div style={{padding:'5px'}}>
                                        Aún no hay ningún ticket en curso
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{height:'20px'}}/>
                        <div className="tickets-place">
                            <div className="tickets-title">
                                Tickets cerrados
                            </div>
                            <div className="tickets-view">
                                {finishedTickets.length !== 0
                                    ?finishedTickets.map((ticket, index) => {
                                        return <div key={index}>
                                            <div key={index} className="ticket-view">
                                                {ticket.getTicketIcon()}
                                                <TciketInfoSeparetor key={0}/>
                                                <div className="date-view">
                                                    {ticket.getCreated().toDate().toLocaleString("es-ES")}
                                                </div>
                                                <TciketInfoSeparetor key={1}/>
                                                <div className="title-view">
                                                    {ticket.getTitle()}
                                                </div>
                                                <TciketInfoSeparetor key={2}/>
                                                <div className="message-view">
                                                    {ticket.getMessages()[ticket.getMessages().length-1].getMessage()}
                                                </div>
                                            </div>
                                            <Divider/>
                                        </div>})
                                    : <div style={{padding:'5px'}}>
                                        Aún no hay ningún ticket cerrado
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            getTickets();

            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <LoadingBarApp/>
            </div>
        }
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}

export function TciketInfoSeparetor() {
    return <div style={{display:'flex'}}>
        <div style={{width:'5px'}}/>
        <Divider orientation="vertical" flexItem/>
        <div style={{width:'5px'}}/>
    </div>
}