import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthProviderTeacher } from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import Navbar from "../components/navbar";
import Section from "../components/section";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Chapter, Course, PrivateCourse } from "../implementation/course";
import CourseViwerCard, { CourseViwerCardTeacher } from "../components/course-viwer";

export default function TeachersPage() {
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const [userr, setUserr] = useState(null);
    const [reload, setReload] = useState(true);
    const [courses, setCourses] = useState([]);

    function handleOnUserLoggedIn(user) {
        setState(1);
        setUserr(user);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    function handleUserNotTeacher() {
        navigate("/");
    }

    async function getCourses() {
        try {
            const coursesQ = await getDocs(query(collection(db, "courses"), where("teachers", "array-contains", userr.uid)), limit(5));
            console.log(coursesQ);

            await Promise.all(coursesQ.docs.map(async (courseData, index) => {
                console.log(courseData);

                const course = Course.fromDataBase(courseData);
                console.log(course);

                courses.push(course);
            }));

            setReload(false);
        } catch (e) {
            console.error(e);
        }
    }

    if (state === 1) {
        if (reload) {
            getCourses();
        }

        console.log("To show courses");
        console.log(courses);

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <Section title="Mis cursos" onClick={() => {navigate('/teachers/courses/')}}>
                {reload
                    ? <LoadingBarApp/>
                    : <div className="grid">{courses.map((course, index) => {
                        return <CourseViwerCardTeacher key={index} course={course} progress={false} user={userr}/>
                    })}</div>
                }
            </Section>
        </div>
    }

    return <AuthProviderTeacher
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
        onUserNotTeacher={handleUserNotTeacher}>
        <LoadingBarApp/>
    </AuthProviderTeacher>
}