export class PublicUser {
    constructor (name, description) {
        this.name = name;
        this.description = description;
    }

    static fromDatabase(doc) {
        return new PublicUser(doc.get('name'), doc.get('description'));
    }

    static usernameRegex = /^([a-zA-Z0-9_-]).{6,20}$/;

    toFirebase() {
        return {
            'name': this.name,
            'description': this.description
        }
    }

    getName() {
        return this.name;
    }

    updateName(name) {
        this.name = name;
    }

    validateName() {
        console.log(!(this.name === null || this.name === ""));
        console.log(PublicUser.usernameRegex.test(this.name));
        return !(this.name === null || this.name === "") && PublicUser.usernameRegex.test(this.name);
    }

    getDescription() {
        return this.description;
    }

    updateDescription(description) {
        this.description = description;
    }

    validateDescription() {
        return !(this.description === null || this.description === "");
    }

    validateAll() {
        return this.validateName() && this.validateDescription();
    }
}