import { useNavigate } from 'react-router-dom';
import '../style/section.css';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function Section({ children, title, onClick = null }) {
    return <div className="my-section">
        {onClick
            ? <div className="section-title"><div className='clickable' onClick={onClick} style={{width:'fit-content'}}>{title}</div></div>
            : <div className="section-title">{title}</div>
        }
        <div className="section-body">{children}</div>
    </div>;
}

export function ExpandableSection({ children, title}) {
    const [expanded, setExpanded] = useState(true);

    return <div className="my-section">
        <div className="section-title expandable clickable" onClick={() => {
            setExpanded(!expanded);
        }}>
            <div>{title}</div>
            {expanded
                ? <KeyboardArrowUpIcon style={{margin:'7px 7px 7px auto'}}/>
                : <KeyboardArrowDownIcon style={{margin:'7px 7px 7px auto'}}/>
            }
        </div>
        {expanded
            ? <div className="section-body-expandable-expanded">{children}</div>
            : <div className="section-body-expandable">{children}</div>
        }
        
    </div>;
}