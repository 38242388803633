import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthProvider from '../components/auth-provider';
import LoadingBarApp from '../components/loading';
import '../style/tickets.css';
import Navbar from '../components/navbar';
import { useState } from 'react';
import { Box, Button, CircularProgress, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, List, ListItemText, MenuItem, Modal, Select, TextField, ThemeProvider } from '@mui/material';
import { Ticket } from '../implementation/ticket';
import DialogSelectCourse from '../components/dialog-select-course';
import { addDoc, collection, doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { Course } from '../implementation/course';
import EditIcon from '@mui/icons-material/Edit';
import DialogSelectTemas from '../components/dialog-select-temas';

export default function CreateTicket() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [selectedCourse, setSelectedCourse] = useState(searchParams.get('course'));
    const [courseName, setCourseName] = useState(null);
    const [openSelectCourse, setOpenSelectCourse] = useState(false);

    const [selectedTemas, setSelectedTemas] = useState([]);
    const [openSelectTemas, setOpenSelectTemas] = useState(false);

    const [openViewSend, setOpenViewSend] = useState(false);

    const [loading, setLoading] = useState(false);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    function handleOnSelect(course) {
        console.log(course);
        navigate(`/createTicket?type=${searchParams.get('type')}&course=${course.getId()}`);
        setSelectedCourse(course.getId());
        setSelectedTemas([]);
        setOpenSelectCourse(false);
    }

    function handleOnSelectTemas(selected) {
        console.log(selected);

        if (selected.length === 0) {
            setSelectedTemas(null);
        } else {
            setSelectedTemas(selected);
        }
        
        setOpenSelectTemas(false);
    }

    function handleSetName(name) {
        setCourseName(name);
    }

    function messageSelection() {
        var retVal = `Solicitud para subir nota del curso ${courseName}: `;

        selectedTemas.map((selected, index) => {
            retVal = retVal + `${selected['topic']} del ${selected['chapter']}`;
            if (selectedTemas.length-1 === index) {
                retVal = retVal + '.';
            } else {
                retVal = retVal + ', ';
            }
        })

        return retVal;
    }

    async function sendData() {
        console.log("Send data");
        setLoading(true);

        try {
            const messages = [];
            messages.push({
                'message': messageSelection(),
                'user': userr.uid
            });

            if (document.getElementById('more-info-text').value!==null && document.getElementById('more-info-text').value.replace(' ', '') !== "")
                messages.push({
                    'message': document.getElementById('more-info-text').value,
                    'user': userr.uid
                });

            await addDoc(collection(db, 'users', userr.uid, 'createdTickets'), {
                'created': Timestamp.now(),
                'title': courseName,
                'type': parseInt(searchParams.get('type')),
                'messages': messages
            })
        } catch (e) {
            console.error(e);
            setLoading(false);
        }

        navigate('/tickets')
    }

    if (state === 1) {
        if (searchParams.get('type') === null || searchParams.get('type') < 0 || searchParams.get('type') > 0) {
            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    Los parametros seleccionados no permiten crear un nuevo ticket.
                </div>
            </div>
        } else {
            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <DialogSelectCourse open={openSelectCourse} onClose={() => {setOpenSelectCourse(false)}} userId={userr.uid} onSelect={handleOnSelect}/>
                {selectedCourse!==null
                    ? <DialogSelectTemas key={selectedCourse} open={openSelectTemas} onClose={() => {setOpenSelectTemas(false)}} courseId={selectedCourse} onSelect={handleOnSelectTemas}/>
                    : null
                }
                <Modal
                    open={loading}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ThemeProvider theme={createTheme({
                        palette: {
                            primary: {
                                main: "#333",
                            }
                        }
                    })}>
                        <Box sx={{
                            position:'absolute', 
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            backgroundColor:'transparent'
                        }}><CircularProgress sx={{
                            display:'block'
                        }} size={'large'} color="primary"/></Box>
                    </ThemeProvider>
                </Modal>
                {selectedCourse !== null && selectedTemas.length !== 0
                    ? <Dialog open={openViewSend} onClose={() => {setOpenViewSend(false)}}>
                        <DialogTitle>Revisión del ticket</DialogTitle>
                        <Divider/>
                        <DialogContent>
                            <div>
                                <div style={{fontSize:'20px', fontWeight:'600'}}>
                                    Mensaje de la solicitud:
                                </div>
                                <div style={{height:'5px'}}/>
                                <div style={{margin:'5px', textAlign:'justify'}}>
                                    {messageSelection()}
                                </div>
                                <div style={{height:'10px'}}/>
                                <div style={{fontSize:'20px', fontWeight:'600'}}>
                                    Mensaje opcional:
                                </div>
                                <div style={{height:'5px'}}/>
                                <div style={{margin:'5px', textAlign:'justify'}}>
                                    {document.getElementById('more-info-text').value===null || document.getElementById('more-info-text').value.replace(' ', '') === ""
                                        ? "No hay ningún mensaje opcional."
                                        : document.getElementById('more-info-text').value
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={() => {setOpenViewSend(false)}}>Cancelar</Button>
                            <Button variant='outlined' onClick={() => {sendData()}}>Enviar</Button>
                        </DialogActions>
                    </Dialog>
                    : null
                }
                <div style={{margin:'5px'}}>
                    <h1 className='title'>
                        Nuevo Ticket
                    </h1>
                    <Divider/>
                    <div style={{display:'flex', alignItems:'center', margin:'5px'}}>
                        <div style={{fontSize:'20px'}}>
                            Tipo de ticket:
                        </div>
                        <div style={{width:'10px'}}/>
                        <div>
                            <FormControl fullWidth>
                                <Select
                                    labelId="ticket-type-label"
                                    id="ticket-type"
                                    value={searchParams.get('type')}
                                    onChange={(event) => {
                                        navigate('/createTicket?type='+event.target.value);
                                    }}
                                >
                                    <MenuItem value={0}>Subir nota</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div style={{height:'10px'}}/>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div style={{fontSize:'20px'}}>
                            Curso:
                        </div>
                        <div style={{width:'10px'}}/>
                        <div>
                            {selectedCourse===null
                                ? <Button variant='outlined' onClick={() => {setOpenSelectCourse(true)}}>
                                    Seleccionar curso
                                </Button>
                                : <div style={{display:'flex'}}>
                                    <SelectedCourseName key={selectedCourse} courseId={selectedCourse} setName={handleSetName}/>
                                    <div style={{width:'15px'}}/>
                                    <Button variant='outlined' onClick={() => {setOpenSelectCourse(true)}}><EditIcon/></Button>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{height:'10px'}}/>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div style={{fontSize:'20px'}}>
                            Temas:
                        </div>
                        <div style={{width:'10px'}}/>
                        <div>
                            {selectedTemas.length === 0
                                ? <Button variant='outlined' onClick={() => {setOpenSelectTemas(true)}} disabled={selectedCourse===null}>
                                    Seleccionar temas
                                </Button>
                                : <div style={{display:'flex'}}>
                                    <List>
                                        {selectedTemas.map((selected, index) => {
                                            return <div key={index}>
                                                <ListItemText primary={`${selected['topic']} del ${selected['chapter']}`}/>
                                                {selectedTemas.length-1 === index
                                                    ? null
                                                    : <Divider/>
                                                }
                                            </div>
                                        })}
                                    </List>
                                    <div style={{width:'15px'}}/>
                                    <Button variant='outlined' onClick={() => {setOpenSelectTemas(true)}}><EditIcon/></Button>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{height:'10px'}}/>
                    <div>
                        <div style={{fontSize:'20px'}}>
                            Más información:
                        </div>
                        <div style={{width:'10px'}}/>
                        <div>
                            <TextField id="more-info-text" variant="outlined" style={{width:'-webkit-fill-available'}}/>
                        </div>
                    </div>
                    <div style={{height:'10px'}}/>
                    <Button style={{width:'-webkit-fill-available'}} variant='outlined' disabled={selectedCourse === null || selectedTemas.length === 0} onClick={() => {setOpenViewSend(true)}}>Revisar y enviar</Button>
                </div>
            </div>
        }
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}

export function SelectedCourseName({ courseId, setName }) {
    const [course, setCourse] = useState(null);

    async function getCourse() {
        const courseDoc = await getDoc(doc(db, 'courses', courseId));
        setCourse(Course.fromDataBase(courseDoc));
    }

    if (course === null) {
        getCourse();
    }

    if (course !== null) {
        setName(course.getTitle());

        return <div>
            <div style={{fontSize:'18px'}}>
                {course.getTitle()}
            </div>
            <Divider/>
            <div style={{fontSize:'12px'}}>
                {course.getId()}
            </div>
        </div>
    }

    return <div style={{minWidth:'100px'}}>
        <LoadingBarApp/>
    </div>
}