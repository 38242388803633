import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import { useState } from "react";
import Navbar from "../components/navbar";
import { arrayUnion, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Message, Ticket } from "../implementation/ticket";

import '../style/tickets.css';
import { Button, CircularProgress, Divider, TextField } from "@mui/material";
import MessageView from "../components/message-view";
import SendIcon from '@mui/icons-material/Send';

export default function TicketView() {
    const navigate = useNavigate();
    const {ticketId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false)

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    const collectionTicket = [
        'createdTickets',
        'disputtedTickets',
        'finishedTickets'
    ];

    async function getTicket() {
        try {
            const ticketDoc = await getDoc(doc(db, 'users', userr.uid, collectionTicket[searchParams.get('type')], ticketId));
            setTicket(await Ticket.fromDatabase(ticketDoc));
        } catch (e) {
            console.error(e);
        }
    }

    async function sendMessage() {
        setLoading(true);
        
        try {
            await updateDoc(doc(db, 'users', userr.uid, collectionTicket[searchParams.get('type')], ticketId), {
                'messages': arrayUnion({
                    'message': document.getElementById('new-message').value,
                    'user': userr.uid,
                    'num': ticket.getMessages().length
                })
            });
            document.getElementById('new-message').value = "";
        } catch (e) {
            console.error(e);
        }
    }

    if (state === 1) {
        if (ticket !== null) {
            console.log(ticket);

            if (!loaded) {
                setLoaded(true);
                onSnapshot(doc(db, 'users', userr.uid, collectionTicket[searchParams.get('type')], ticketId), (docData) => {
                    Ticket.fromDatabase(docData).then(newTicket => {
                        setTicket(newTicket);
                        setLoading(false);
                    });
                });
            }

            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    <h1 className="title">
                        Ticket {ticket.getTitle()}
                    </h1>
                    <div style={{fontSize:'12px', marginLeft:'5px'}}>
                        {ticketId}
                    </div>
                    <div style={{height:'10px'}}/>
                    <Divider/>
                    <div style={{height:'5px'}}/>
                    <div style={{margin:'0px 7px'}}>
                        <div style={{display: 'flex', fontSize:'20px', fontWeight:'500'}}>
                            <div>
                                Fecha de cración:
                            </div>
                            <div style={{width:'5px'}}/>
                            <div>
                                {ticket.getCreated().toDate().toLocaleString("es-ES")}
                            </div>
                        </div>
                    </div>
                    <div style={{height:'10px'}}/>
                    <div style={{margin:'0px 7px'}}>
                        <div style={{display: 'flex', fontSize:'20px', fontWeight:'500'}}>
                            {ticket.getTicketIcon()}
                            <div style={{width:'5px'}}/>
                            <div>
                                {ticket.getTipeText()}
                            </div>
                        </div>
                    </div>
                    <div style={{height:'10px'}}/>
                    <div className="chat-view">
                        {ticket.getMessages().map((message, index) => {
                            console.log(message);
                            return <MessageView key={index} message={message.getMessage()} username={message.getUser() === 'admin'? "Bit Minds" : ticket.getUsers()[message.getUser()].getName()} tooltil={message.getUser() === 'admin'? "Administración de BitMinds" : ticket.getUsers()[message.getUser()].getDescription()} right={message.getUser()===userr.uid} onClick={message.getUser() === 'admin'? null : () => {navigate('/u/'+message.getUser())}}/>
                        })}
                        <div style={{height:'20px'}}/>
                        <div className="message-write-view">
                            <TextField id="new-message" variant="outlined" style={{width:'-webkit-fill-available'}} disabled={parseInt(searchParams.get('type')) !== 1 || loading}/>
                            <div style={{width:'5px'}}/>
                            <Button variant="outlined" disabled={parseInt(searchParams.get('type')) !== 1 || loading} onClick={() => {
                                if (document.getElementById('new-message').value !== null && document.getElementById('new-message').value.replace(' ', '') !== "" )
                                    sendMessage();
                            }}>
                                {loading
                                    ? <CircularProgress/>
                                    : <SendIcon/>
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        } else if (searchParams.get('type') === null || (searchParams.get('type') < 0 || searchParams.get('type') > 2)) {
            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                Los parametros no son correctos para realizar las busqueda de un ticket.
            </div>
        } else {
            getTicket();

            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <LoadingBarApp/>
            </div>
        }
        
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}