import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/navbar";
import LoadingBarApp from "../components/loading";
import AuthProvider from "../components/auth-provider";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { db, storage } from "../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import { DocDownload } from "../components/doc-download";
import { Divider } from "@mui/material";

export default function Resourses() {
    const {courseId} = useParams();
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);
    const [docs, setDocs] = useState(null);
    const [courseName, setCourseName] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getDocs() {
        console.log("Getting docs");

        var newCourseName = "{ERROR NAME}";
        var newCourses = [];
        try {
            const docData = await getDoc(doc(db, 'courses', courseId));
            newCourseName = docData.get('title');
        } catch (e) {
            console.error(e);
        }

        try {
            const listedDocs = await listAll(ref(storage, 'courses/'+courseId+'/private/docs'));           
            await Promise.all(listedDocs.items.map(async (docData, index) => {
                console.log(docData.name);
                newCourses.push(docData);
            })) 
        } catch (e) {
            console.error(e);
        }

        setCourseName(newCourseName);
        setDocs(newCourses);
    }

    if (state === 1) {
        if (docs !== null) {
            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    <h1 style={{fontSize:'50px', margin:0, marginBottom:'4px'}}>
                        Recursos de aprendizage para {courseName}
                    </h1>
                    <Divider/>
                    <div style={{margin:'5px'}}>
                        {docs.length !== 0
                            ? <div style={{display:'flex', flexWrap:'wrap'}}>
                                {docs.map((docData, index) => {
                                    console.log(docData);
                                    return <DocDownload key={index} doc={docData} />
                                })}
                            </div>
                            : <div>
                                Aún no hay recursos de aprendizages para <span style={{fontWeight:'600'}}>{courseName}</span>.
                            </div>
                        }
                    </div>
                </div>
            </div>
        } else {
            getDocs();

            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <LoadingBarApp/>
            </div>
        }
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}