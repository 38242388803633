import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#708090"
        }
    }
})

export const secondaryTheme = createTheme({
    palette: {
        primary: {
            main: "#536878"
        }
    }
})