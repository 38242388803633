import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, createTheme, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Step, StepButton, Stepper, styled, ThemeProvider, Tooltip, Typography } from '@mui/material';
import pythonLogo from '../assets/icons/python.svg';
import arduinoLogo from '../assets/icons/arduino.svg';
import catIcon from '../assets/lang/ca.svg';
import espIcon from '../assets/lang/es.svg';
import "../style/course-card.css";
import { Fragment, useState } from 'react';
import LoadingBarApp from '../components/loading';
import { getDownloadURL, getMetadata, listAll, ref, uploadBytes } from 'firebase/storage';
import { auth, db, storage } from '../firebase/firebase';
import { act } from 'react-dom/test-utils';
import VideoPlayerApp from '../components/video-player';
import { collection, doc, getDoc, getDocs, onSnapshot, runTransaction, setDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { getRandomInt } from '../components/my-math';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import { ChangeCircleOutlined, CloudDownloadOutlined, CloudUpload, CloudUploadOutlined, DeleteOutline, QuestionAnswer, SendOutlined } from '@mui/icons-material';
import QuestionAnswers from '../components/questionario-respond';
import '../style/file-download.css';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { openInNewTab } from './functionalities';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as NexUi from '@nextui-org/react';
import CircularProgressApp from '../components/circular-progress';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '../components/customUi';
import { secondaryTheme, theme } from '../style/theme';
import { save } from './saveBlob';

export class Course {
    constructor(title, id, photoUrl, active, description, capitulos, idioma, level, max_age, min_age, users_num, valorations_num, valoration, lenguajes, video, price, secondPrice, totalExercices, totalHours, totalPages) {
        this.title = title;
        this.id = id;
        this.photoUrl = photoUrl;
        this.active = active;
        this.description = description;
        this.capitulos = capitulos;
        this.idioma = idioma;
        this.level = level;
        this.max_age = max_age;
        this.min_age = min_age;
        this.users_num = users_num;
        this.valorations_num = valorations_num;
        this.valoration = valoration;
        this.lenguajes = lenguajes;
        this.video = video;
        this.price = price;
        this.secondPrice = secondPrice;
        this.totalExercices = totalExercices;
        this.totalHours = totalHours;
        this.totalPages = totalPages;
    }

    static fromDataBase(doc) {
        try {
            return new Course(doc.get('title'), doc.id, doc.get('photo'), doc.get('active'), doc.get('description'), doc.get('capitulos'), doc.get('idioma'), doc.get('level'), doc.get('max_age'), doc.get('min_age'), doc.get('users').length, 0, 0, doc.get('lenguajes'), doc.get("video"), null, null, doc.get('totalExercices'), doc.get('totalHours'), doc.get('totalPages'));
        } catch (e) {
            console.error(e);
        }
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        const retStr = this.description;
        return retStr.replace(/\n/g, "<br>");
    }

    getId() {
        return this.id;
    }

    getPhotoUrl() {
        return this.photoUrl;
    }

    getUsersNum() {
        return this.users_num;
    }

    getValorationsNum() {
        return this.valorations_num;
    }

    getValoration() {
        return this.valoration;
    }

    getLenguajes() {
        return this.lenguajes;
    }

    getCaps() {
        return this.capitulos;
    }

    getCapsLen() {
        return this.capitulos.length;
    }

    getLevelString() {
        switch(this.level) {
            case 1:
                return 'browse.level.basic';
            case 2:
                return 'browse.level.basicDedicated';
            case 3:
                return 'browse.level.dedicated';
            case 4:
                return 'browse.level.advanced';
            default:
                return "";
        }
    }

    getIdiomaIcon() {
        switch(this.idioma) {
            case "CAT":
                return <Tooltip key={"cat"} title={"Català"}><img src={catIcon} className='idioma-icon' width={20} style={{borderRadius:0}}/></Tooltip>;
            case "ESP":
                return <Tooltip key={"esp"} title={"Español"}><img src={espIcon} className='idioma-icon' width={20} style={{borderRadius:0}}/></Tooltip>;
        }
    }

    getLenguajeIcons() {
        var retVal = [];
        
        this.lenguajes.forEach((item) => {
            switch (item) {
                case "python":
                    retVal.push(<Tooltip key={"python"} title={"Python"}><img src={pythonLogo} className='lenguaje-icon' width={20}/></Tooltip>);
                    break;
                case "arduino":
                    retVal.push(<Tooltip key={"arduino"} title={"Arduino"}><img src={arduinoLogo} className='lenguaje-icon' width={20}/></Tooltip>);
                    break;
            }
        });

        return retVal;
    }

    getTotalCaps() {
        return this.capitulos.length;
    }

    getIdioma() {
        return this.idioma;
    }

    getVideoUrl() {
        return this.video;
    }

    getPrice() {
        return this.price;
    }

    getSecondPrice() {
        return this.secondPrice;
    }

    getProgress() {
        if (this.progress) {
            return this.progress;
        } else {
            return 0;
        }
    }

    getPrivateData() {
        return this.privateCourse;
    }


    buyedCourse() {
        return this.privateCourse !== null;
    }

    getTotalExercices() {
        return this.totalExercices;
    }

    getTotalHours() {
        return this.totalHours;
    }

    getTotalPages() {
        return this.totalPages;
    }


    setValorationsNum(valorations_num) {
        this.valorations_num = valorations_num;
    }

    setValoration(valoration) {
        this.valoration = valoration;
    }

    updatePrivateCourse(privateCourse) {
        this.privateCourse = privateCourse;
    }

    updatePrice(price) {
        this.price = price;
    }

    updateSecondPrice(secondPrice) {
        this.secondPrice = secondPrice;
    }

    updateProgress(progress) {
        this.progress = progress;
    }

    drawCartCard(onDelete) {
        return <div className='cart-course-card'>
            <div className='cart-title'>{this.title}</div>
            <div style={{height:'5px'}}/>
            <div className='cart-price'>
                <div>
                    <ThemeProvider theme={createTheme({
                        palette: {
                            primary: {
                                main: "#36454f"
                            }
                        }
                    })}>
                        <RoundButton text={"Quitar"} endIcon={<DeleteOutline/>} onClick={onDelete}/>
                    </ThemeProvider>
                </div>
                <div style={{marginLeft:'auto', textAlign:'end'}}>
                    {this.secondPrice!==null
                        ? <div>
                            <div style={{fontSize:'12px', textDecoration:'line-through'}}>
                                {(this.price/100).toFixed(2)}€
                            </div>
                            <div>{(this.secondPrice/100).toFixed(2)}€</div>
                        </div>
                        : <div></div>
                    }
                </div>
            </div>
            <div style={{height:'5px'}}/>
            <Divider/>
        </div>;
    }
}

export class PrivateCourse {
    constructor(chapters, nota=null) {
        this.chapters = chapters;
        this.nota = nota;
    }

    getChapters() {
        return this.chapters;
    }

    getChapterAt(index) {
        return this.chapters[index];
    }

    getNota() {
        return this.nota;
    }
}

export class Chapter {
    constructor(title, description, topics, video, videoUrl, docs, completed, videoComplated) {
        this.title = title;
        this.description = description;
        this.topics = topics;
        this.video = video;
        this.videoUrl = videoUrl;
        this.docs = docs;
        this.completed = completed;
        this.videoComplated = videoComplated;
    }

    static fromDataBase(doc) {
        const topics = [];
        doc.get('topics').forEach(topic => {
            console.warn("The topic getting now is: ");
            var video = null;
            if (topic['video']) {
                video = topic['video'];
            }

            var cuestionario = [null, null, null];
            if (topic['cuestionario']) {
                cuestionario = [topic['cuestionario']['ref'], topic['cuestionario']['num'], topic['cuestionario']['answers']];
            }

            var code = [null, null, null]
            if (topic['code']) {
                code = [topic['code']['intents'], topic['code']['max_size'], topic['code']['route']];
            }

            topics.push(new Topic(topic['title'], topic['description'], video, cuestionario[0], cuestionario[1], cuestionario[2], null, null, null, code[0], code[1], code[2], null));
        });
        return new Chapter(doc.get('title'), doc.get('description'), topics, doc.get('video'), null, doc.get('docs'), null, null);
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        return this.description;
    }

    getTopics() {
        return this.topics;
    }

    getTopicAt(index) {
        return this.topics[index];
    }

    getVideo() {
        return this.video;
    }

    getVideoUrl() {
        return this.videoUrl;
    }

    getComplated() {
        return this.completed;
    }

    getVideoComplated() {
        return this.videoComplated;
    }

    getDocs() {
        return this.docs;
    }

    updateVideoUrl(videoUrl) {
        this.videoUrl = videoUrl;
    }

    updateComplated(complated) {
        this.completed = complated;
    }

    updateVideoComplated(videoComplated) {
         this.videoComplated = videoComplated;
    }
}

export class Topic {
    constructor(title, description, video, questionsRef, questionsNum, answersRef, complated, progress, nota, codeIntents, codeMax, codeRoute, codeBlock) {
        this.title = title;
        this.description = description;
        this.video = video;
        this.questionsRef = questionsRef;
        this.questionsNum = questionsNum;
        this.answersRef = answersRef;
        this.complated = complated;
        this.progress = progress;
        this.nota = nota;
        this.codeIntents = codeIntents;
        this.codeMax = codeMax;
        this.codeRoute = codeRoute;
        this.codeBlock = codeBlock;
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        return this.description;
    }

    getVideo() {
        return this.video;
    }

    getQuestionsRef() {
        return this.questionsRef;
    }

    getAnswersRef() {
        return this.answersRef;
    }

    getQuestionsNum() {
        return this.questionsNum;
    }

    getComplated() {
        return this.complated;
    }

    getNota() {
        return this.nota;
    }

    getProgress() {
        return this.progress;
    }

    getCodeIntents() {
        return this.codeIntents;
    }

    getCodeMax() {
        return this.codeMax;
    }

    getCodeRoute() {
        return this.codeRoute;
    }

    getCodeBlock() {
        return this.codeBlock;
    }

    updateComplated(complated) {
        this.complated = complated;

        if (this.codeIntents) {
            this.codeBlock = complated;
        }
    }

    updateProgress(progress) {
        this.progress = progress;
    }

    updateNota(nota) {
        this.nota = nota;
    }
}

export class Steps {
    constructor(title, description, content) {
        this.title = title;
        this.description = description;
        this.content = content;
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        return this.description;
    }

    getContent() {
        return this.content;
    }
}

export class Video {
    constructor(storageUrl, vidUrl, video) {
        this.storageUrl = storageUrl;
        this.vidUrl = vidUrl;
    }

    getStorageUrl() {
        return this.storageUrl;
    }

    getVideoUrl() {
        return this.vidUrl;
    }

    updateUrl(url) {
        this.vidUrl = url;
    }
}

export class Cuestionario{
    constructor(cuestionsNum, cuestionsRef, currentCuestionario, answersRef, correctedCuestionarios) {
        this.cuestionsNum = cuestionsNum;
        this.cuestionsRef = cuestionsRef;
        this.currentCuestionario = currentCuestionario;
        this.answersRef = answersRef;
        this.correctedCuestionarios = correctedCuestionarios;
    }

    getRef() {
        return this.cuestionsRef;
    }

    getNum() {
        return this.cuestionsNum;
    }

    getAnswersRef() {
        return this.answersRef;
    }

    getCurrentCuestgionario() {
        return this.currentCuestionario;
    }

    getCorrectedCuestionarios() {
        return this.correctedCuestionarios;
    }

    updateCurrentCuestionario(currentCuestionario) {
        this.currentCuestionario = currentCuestionario;
    }

    updateCorrectedCuestionarios(correctedCuestionarios) {
        this.correctedCuestionarios = correctedCuestionarios;
    }
}

export class CurrentCuestionario {
    constructor(questions) {
        this.questions = questions;
    }

    getQuestions() {
        return this.questions;
    }

    getQuestionAt(index) {
        return this.questions[index];
    }
}

export class Cuestions {
    constructor(question, answers, respond, id) {
        this.question = question;
        this.answers = answers;
        this.respond = respond;
        this.id = id;
    }

    getQuestion() {
        return this.question;
    }

    getAnswers() {
        return this.answers;
    }

    getRespond() {
        return this.respond;
    }

    getId() {
        return this.id;
    }

    updateRespond(respond) {
        this.respond = respond;
    }
}

export class CorrectedCuestionario {
    constructor(questions, nota) {
        this.questions = questions;
        this.nota = nota;
    }

    getNota() {
        return this.nota;
    }

    getQuestions() {
        return this.questions;
    }
}

export class CorrectedCuestions {
    constructor(question, answers, respond, correct) {
        this.question = question;
        this.answers = answers;
        this.respond = respond;
        this.correct = correct;
    }

    getQuestion() {
        return this.question;
    }

    getAnswers() {
        return this.answers;
    }

    getRespond() {
        return this.respond;
    }

    getCorrect() {
        return this.correct;
    }

    updateRespond(respond) {
        this.respond = respond;
    }
}

export class CodePublish {
    constructor (intents, maxSize, route, block, canPublish) {
        this.intents = intents;
        this.maxSize = maxSize;
        this.route = route;
        this.block = block;
        this.canPublish = canPublish;
    }

    getIntents() {
        return this.intents;
    }

    getCanPublish() {
        return this.canPublish;
    }

    updateCanPublish(newCanPublish) {
        this.canPublish = newCanPublish;
    }
}

export class Resultado{
    constructor(nota) {
        this.nota = nota;
    }
}

export class PublishResult{
    constructor (nota) {
        this.nota = nota;
    }
}

export function VideoViwer({ step }) {
    const [currentStep, setCurrentStep] = useState(step);
    const [fakeState, setFakeState] = useState(false);
    
    async function getVideoUrl() {
        const url = await getDownloadURL(ref(storage, currentStep.getStorageUrl()));
        currentStep.updateUrl(url);
        setFakeState(!fakeState);
    }

    if (currentStep.getVideoUrl() === null) {
        getVideoUrl();

        return <LoadingBarApp/>;
    } else {
        return <div>
            <VideoPlayerApp url={currentStep.getVideoUrl()}/>
        </div>
    }
}

export function CuestionarioViwer({ step, chapter, topic, setCurrentCuestionario, saveCuestionario, firstIdx, lastIdx, userId, sendCuestionario, setCorrectedCuestionarios, updateNotaTopic, setThisComplated }) {
    const {courseId} = useParams();

    const [currentStep, setCurrentStep] = useState(step);
    const [fakeState, setFakeState] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [currentIntent, setCurrentIntent] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);

    function getChapterString() {
        var retVal = "";

        if (chapter+1<10)
            retVal = '0'

        return retVal+(chapter+1);
    }

    function arrayContainsQuestion(questions, questionId) {
        var retVal = false;
        console.warn("To compare: ");
        questions.forEach(question => {
            console.warn(question);
            if (question.id === questionId)
                retVal = true;
        });

        return retVal;
    }

    function findFirstNotUsed(allDocs, currentDocs) {
        var retVal = null;
        var founded = false;
        allDocs.forEach(searchingDoc => {
            if (!founded) {
                var samein = false;
                currentDocs.forEach(actualDoc => {
                    if (!founded) {
                        if (searchingDoc.id === actualDoc.id){
                            samein = true;
                        }
                            
                    }
                });
                if (!samein) {
                    retVal = searchingDoc;
                    founded = true;
                }
            }
        });

        return retVal;
    }

    function getRandomQuestions(docs, num) {
        const retVal = [];

        var searching = true;

        for (var i=0; i<docs.length&&searching; i++) {
            var searched = false;
            for (var y=0; y<3&&!searched; y++) {
                const num = getRandomInt(docs.length);

                if (!arrayContainsQuestion(retVal, docs[num].id)) {
                    retVal.push(docs[num]);
                    searched = true;
                }
            }

            if (!searched) {
                const searchedNew = findFirstNotUsed(docs, retVal);

                if (searchedNew !== null) {
                    retVal.push(searchedNew);
                }
            }

            if (i>=num)
                searching = false;
        }

        return retVal;
    }

    async function generateCuestionario() {
        const questions = await getDocs(collection(db, 'courses', courseId, 'chapters', getChapterString(), currentStep.getContent().getRef()));
        await questions.docs.forEach(question => {
        });
        const cuestionarioQuestions = getRandomQuestions(questions.docs, step.getContent().getNum());
        const questionsRet = [];

        await cuestionarioQuestions.forEach(question => {
            const answers = [];
            question.get('answers').forEach(answer => {
                answers.push(answer);
            });
            questionsRet.push(new Cuestions(question.get('question'), answers, null, question.id));
        });

        currentStep.getContent().updateCurrentCuestionario(new CurrentCuestionario(questionsRet));
        setCurrentCuestionario(currentStep.getContent().getCurrentCuestgionario());

        setLoaded(true);

        saveCuestionario();
    }

    function stringToIntAnsw(strAnswer) {
        switch(strAnswer) {
            case("a"):
                return 0;
            case("b"):
                return 1;
            case("c"):
                return 2;
            case("d"):
                return 3;
        }
    }

    function handleSaveAnswer(index, answer) {
        currentStep.getContent().getCurrentCuestgionario().getQuestionAt(index).updateRespond(stringToIntAnsw(answer));
    }

    async function getCurrentCurrentCuestionario() {
        try {
            const cuestionarioId = 'cuestionario'+firstIdx+lastIdx;
            const currentCuestionario = await getDoc(doc(db, 'courses', courseId, 'progress', userId, 'cuestionarios', cuestionarioId));
            if (currentCuestionario.data()) {
                const questionsRet = [];
                await currentCuestionario.get('questions').forEach(async question => {
                    const questionData = await getDoc(doc(db, 'courses', courseId, 'chapters', getChapterString(), currentStep.getContent().getRef(), question['id']));

                    const answers = [];
                    await questionData.get('answers').forEach(answer => {
                        answers.push(answer);
                    });
                    questionsRet.push(new Cuestions(questionData.get('question'), answers, question['respond'], questionData.id));

                    if (currentCuestionario.get('questions').length === questionsRet.length) {
                        currentStep.getContent().updateCurrentCuestionario(new CurrentCuestionario(questionsRet));
                        setCurrentCuestionario(currentStep.getContent().getCurrentCuestgionario());
                        setLoaded(true);
                    }
                });
            } else {
                await generateCuestionario();
            }
            
        } catch (e) {
            console.error(e);
            await generateCuestionario();
        }
    }

    async function getCurrentCuestionario() {
        if (currentStep.getContent().getCorrectedCuestionarios() !== null) {
            if ((currentStep.getContent().getCorrectedCuestionarios() !== null && currentStep.getContent().getCorrectedCuestionarios().length !== 0) && currentStep.getContent().getCorrectedCuestionarios()[currentStep.getContent().getCorrectedCuestionarios().length-1].getNota() >= 50) {
                currentStep.getContent().updateCurrentCuestionario(-1);
                setLoaded(true);
                setFakeState(!fakeState);

                setThisComplated();

                updateNotaTopic(currentStep.getContent().getCorrectedCuestionarios()[currentStep.getContent().getCorrectedCuestionarios().length-1].getNota());
            } else {
                await getCurrentCurrentCuestionario();
            }
        } else {
            try {
                const cuestionarios = [];
                const correctedCuestionarios = await getDocs(collection(db, 'courses', courseId, 'progress', userId, 'correctedCuestionarios'));
                
                var orderedDocs = [];
    
                console.warn("Ordering data: ");
                await correctedCuestionarios.docs.forEach(async docRef => {
                    if (orderedDocs.length === 0) {
                        if (docRef.id.startsWith(''+firstIdx+lastIdx))
                            orderedDocs.push(docRef);
                    } else {
                        const splittedId = docRef.id.split('');

                        if (splittedId[0] == firstIdx && splittedId[1] == lastIdx) {
                            var actualId = '';
                            for (var i=2; i<splittedId.length; i++) {
                                actualId = actualId+splittedId[i];
                            }
        
                            var inserted = false;
                            await orderedDocs.forEach((docReff, index) => {
                                if (!inserted) {
                                    const searchingSlittedId = docReff.id.split('');
        
                                    var searchingActualId = '';
                                    for (var y=2; y<searchingSlittedId.length; y++) {
                                        searchingActualId = searchingActualId+searchingSlittedId[y];
                                    }
        
                                    if (parseInt(searchingActualId) > parseInt(actualId)) {
                                        inserted = true;
        
                                        const newDocs = [
                                            ...orderedDocs.slice(0, index),
                                            docRef,
                                            ...orderedDocs.slice(index)
                                        ];
        
                                        orderedDocs = newDocs;
                                    }
                                }
                            });
        
                            if (!inserted) {
                                orderedDocs.push(docRef);
                            }
                        }
    
                        
                    }
                });
                
                await orderedDocs.forEach(async docRef => {
                    const questions = [];
    
                    await docRef.get('questions').forEach(async questionRef => {
                        const question = await getDoc(doc(db, 'courses', courseId, 'chapters', getChapterString(), currentStep.getContent().getRef(), questionRef['id']));
    
                        const answers = [];
                        await question.get('answers').forEach(answer => {
                            answers.push(answer);
                        });
    
                        questions.push(new CorrectedCuestions(question.get('question'), answers, questionRef['respond'], questionRef['answer']));
                    });
    
                    cuestionarios.push(new CorrectedCuestionario(questions, docRef.get('nota')));
                })
    
                if (cuestionarios.length !== 0) {
                    setCurrentIntent(''+firstIdx+lastIdx+(cuestionarios.length));
                    currentStep.getContent().updateCorrectedCuestionarios(cuestionarios);
                }
    
                setCorrectedCuestionarios(currentStep.getContent().getCorrectedCuestionarios());
    
                if ((currentStep.getContent().getCorrectedCuestionarios() !== null && currentStep.getContent().getCorrectedCuestionarios().length !== 0) && currentStep.getContent().getCorrectedCuestionarios()[currentStep.getContent().getCorrectedCuestionarios().length-1].getNota() >= 50) {

                    currentStep.getContent().updateCurrentCuestionario(-1);
                    setLoaded(true);

                    setThisComplated();

                    updateNotaTopic(currentStep.getContent().getCorrectedCuestionarios()[currentStep.getContent().getCorrectedCuestionarios().length-1].getNota());
                } else {
                    await getCurrentCurrentCuestionario();
                }
            } catch (e) {
                console.error(e);
                await getCurrentCurrentCuestionario();
            }
        }
    }

    if (!loaded) {
        getCurrentCuestionario();

        return <LoadingBarApp/>
    }

    if ((currentStep.getContent().getCurrentCuestgionario() !== -1 && (currentStep.getContent().getCurrentCuestgionario().getQuestions().length === 0 || (currentStep.getContent().getCorrectedCuestionarios() !== null && currentStep.getContent().getCorrectedCuestionarios().length === 0))) || (currentStep.getContent().getCurrentCuestgionario() === -1 && currentStep.getContent().getCorrectedCuestionarios() !== null && currentStep.getContent().getCorrectedCuestionarios()[currentStep.getContent().getCorrectedCuestionarios().length-1].getQuestions().length === 0)) {
        setTimeout(() => {setFakeState(!fakeState); console.log("FakeState setted");}, 500);

        return <LoadingBarApp/>
    }

    const theme = createTheme({
        palette: {
            success: {
                main: "#8bff92"
            }
        }
    });

    if (currentIntent != null) {
        const newCorrected = onSnapshot(doc(db, 'courses', courseId, 'progress', userId, 'correctedCuestionarios', currentIntent), (doc) => {

            if (doc.data()) {
                setCurrentIntent(null);

                currentStep.getContent().updateCorrectedCuestionarios(null);
                currentStep.getContent().updateCurrentCuestionario(null);

                setLoadingSend(false);
                setLoaded(false);
            }
        });
    } else {
        setCurrentIntent(''+firstIdx+lastIdx+'0');
    }

    var questionNum = 0;
    return <div>
        {currentStep.getContent().getCurrentCuestgionario() === -1
            ? <div style={{border:'2px solid #333', borderRadius:'10px', padding:'10px', marginTop:'10px'}}>
                <div style={{fontSize:'45px', fontWeight:'700'}}>
                    Nota: {currentStep.getContent().getCorrectedCuestionarios()[currentStep.getContent().getCorrectedCuestionarios().length-1].getNota().toFixed(2)}%
                </div>
                <Divider/>
                {currentStep.getContent().getCorrectedCuestionarios()[currentStep.getContent().getCorrectedCuestionarios().length-1].getQuestions().map((question, qIndex) => {
                    return <div key={qIndex} style={{marginTop:'5px', marginBottom:'5px'}}>
                        <div>
                            {qIndex+1}. {question.getQuestion()}
                        </div>
                        <ThemeProvider theme={theme}>
                            <FormControl disabled style={{marginLeft:'10px'}}>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={question.getRespond()}
                                >
                                    {question.getAnswers().map((answer, aIndex) => {
                                        return <FormControlLabel key={aIndex} value={aIndex} control={<Radio/>} label={answer} sx={{backgroundColor:(aIndex === question.getRespond() && question.getCorrect())? "#c0fac4" : (aIndex === question.getRespond() && !question.getCorrect())? "#fab1b4" : 'white', borderRadius:'5px', paddingRight:'5px'}}/>
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </ThemeProvider>
                    </div>
                })}
            </div>
            : <div style={{border:'2px solid #333', borderRadius:'10px', padding:'10px', marginTop:'10px'}}>
                {currentStep.getContent().getCurrentCuestgionario().getQuestions().map(question => {
                    questionNum++;
                    return <div key={question.getQuestion()} style={{margin:'5px', marginBottom:'15px'}}>
                        <div>
                            {questionNum + ". " + question.getQuestion()}
                        </div>
                        <div style={{margin:'5px', marginLeft:'30px'}}>
                            <QuestionAnswers step={step} index={questionNum-1} question={question} saveAnswer={handleSaveAnswer}/>
                        </div>
                    </div>;
                })}
                <div style={{display:'flex'}}>
                    <Button variant="outlined" endIcon={!loadingSave? <SaveIcon/> : null} disabled={loadingSave || loadingSend} sx={{margin:'5px', width:'-webkit-fill-available'}} onClick={async () => {
                        setLoadingSave(true);
                        await saveCuestionario();
                        setLoadingSave(false);
                    }}>
                        {!loadingSave? "Guardar respuestas" : <CircularProgress/>}
                    </Button>
                    <Button variant="outlined" endIcon={!loadingSend? <SendIcon/> : null} disabled={loadingSend || loadingSave} sx={{margin:'5px', width:'-webkit-fill-available'}} onClick={async () => {
                        setLoadingSend(true);
                        await sendCuestionario(currentStep.getContent().getCurrentCuestgionario());
                    }}>
                        {!loadingSend? "Enviar y terminar" : <CircularProgress/>}
                    </Button>
                </div>
            </div>
        }
        <div style={{height:'10px'}}/>
        <div className="docs-download">
            <div className="docs-dwl-title">
                Intentos anteriores
            </div>
            <div>
                {currentStep.getContent().getCorrectedCuestionarios() !== null
                ? currentStep.getContent().getCorrectedCuestionarios().map((cuestionario, index) => {
                    return <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Intento {index+1} : {cuestionario.getNota().toFixed(2)}%
                        </AccordionSummary>
                        <AccordionDetails>
                            {cuestionario.getQuestions().map((question, qIndex) => {
                                return <div key={qIndex} style={{marginTop:'5px', marginBottom:'5px'}}>
                                    <div>
                                        {qIndex+1}. {question.getQuestion()}
                                    </div>
                                    <ThemeProvider theme={theme}>
                                        <FormControl disabled style={{marginLeft:'10px'}}>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={question.getRespond()}
                                            >
                                                {question.getAnswers().map((answer, aIndex) => {
                                                    return <FormControlLabel key={aIndex} value={aIndex} control={<Radio/>} label={answer} sx={{backgroundColor:(aIndex === question.getRespond() && question.getCorrect())? "#c0fac4" : (aIndex === question.getRespond() && !question.getCorrect())? "#fab1b4" : 'white', borderRadius:'5px', paddingRight:'5px'}}/>
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    </ThemeProvider>
                                </div>
                            })}
                        </AccordionDetails>
                    </Accordion>
                })
                : <div style={{margin:'5px'}}>
                    Aún no hay intentos realizados
                </div>
                }
            </div>
        </div>
    </div>
}

export function CodePublisViwer( { step, firstIdx, lastIdx }) {
    const { t } = useTranslation();
    const { courseId } = useParams();

    const [settingUp, setSettingUp] = useState(false);
    const [publishInfo, setPublishInfo] = useState(null);
    const [docName, setDocName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    async function getPublishData() {
        try {
            const publishDataDoc = await getDoc(doc(db, 'courses', courseId, 'progress', auth.currentUser.uid, 'publishedData', `${firstIdx}${lastIdx}`));
            
            if (publishDataDoc.data() === undefined) {
                step.getContent().updateCanPublish(true);
                setPublishInfo({
                    active: true,
                    download: null,
                    intents: 0,
                    nota: null,
                    coment: null
                });
            } else {
                const name = await getMetadata(ref(storage, publishDataDoc.get('download')));
                //const type = name.name.split(".");

                step.getContent().updateCanPublish(publishDataDoc.get('active'));
                setDocName(name.name);
                setPublishInfo(publishDataDoc.data());
            }
        } catch (e) {
            step.getContent().updateCanPublish(true);
            setPublishInfo({
                active: true,
                download: null,
                intents: 0,
                nota: null,
                coment: null
            });
        }
    }

    async function uploadFile() {
        try {
            const countList = await listAll(ref(storage, `/courses/${courseId}/private/codes/${auth.currentUser.uid}`));
            const count = countList.items.length;

            const nameFileSplitted = selectedFile.name.split(".");
            const response = await uploadBytes(ref(storage, `/courses/${courseId}/private/codes/${auth.currentUser.uid}/${count}_${selectedFile.name}_${firstIdx}${lastIdx}.${nameFileSplitted[nameFileSplitted.length-1]}`), selectedFile);
            
            var countt = false;
            const onResopnse = onSnapshot(doc(db, 'courses', courseId, 'progress', auth.currentUser.uid, 'publishedData', `${firstIdx}${lastIdx}`), docData => {
                console.log("Update");
                if (countt) {
                    setPublishInfo(null);
                    setSettingUp(false);

                    console.log("Unsubscribing");
                    console.log(docData.data());

                    onResopnse();
                } else {
                    console.log("Plus one in countt");
                    countt=true;
                }
            });
        } catch (e) {
            console.error(e);
        }
    }

    if (!settingUp) {
        setSettingUp(true);
        getPublishData();
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return <div style={{margin:'15px 5px 5px 5px'}}>
        <div>{t('course.screens.publishCode.info')}</div>
        <div style={{height:'10px'}}/>
        {publishInfo != null
            ? <div>
                <div>
                    <div style={{fontSize:'20px', fontWeight:'600'}}>
                        {t('course.screens.publishCode.intentsTitle')}: {publishInfo['intents'] === 0
                            ? t('course.screens.publishCode.noIntents')
                            : `${publishInfo['intents']} ${publishInfo['intents']===1? t('course.screens.publishCode.intentSingular') : t('course.screens.publishCode.intentPlural')}`
                        }
                    </div>
                    <div style={{fontSize:'18px', fontWeight:'500'}}>
                        {step.getContent().getIntents()} {t('course.screens.publishCode.max')}
                    </div>
                    <div>
                        <div style={{marginTop:'20px', display:'flex', minHeight:'100px', alignItems:'center'}}>
                            <div style={{width: '45%'}}>
                                {publishInfo['active']
                                    ? <div>
                                        {selectedFile === null
                                            ? <ThemeProvider theme={theme}>
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    variant="contained"
                                                    tabIndex={-1}
                                                    startIcon={<CloudUploadOutlined />}
                                                >
                                                    {t('course.screens.publishCode.buttonText')}
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        onChange={(event) => {
                                                            setSelectedFile(event.target.files[0]);
                                                        }}
                                                    />
                                                </Button>
                                            </ThemeProvider>
                                            : <div>
                                                <div style={{fontSize:'20px', fontWeight:'700'}}>
                                                    Archivo seleccionado:
                                                </div>
                                                <div style={{height:'5px'}}/>
                                                <div style={{fontWeight:'600'}}>
                                                    {selectedFile.name}
                                                </div>
                                                <div style={{height:'20px'}}/>
                                                <div style={{display:'flex', flexWrap:'wrap'}}>
                                                    <ThemeProvider theme={theme}>
                                                        <Button
                                                            component="label"
                                                            role={undefined}
                                                            variant="contained"
                                                            tabIndex={-1}
                                                            startIcon={<ChangeCircleOutlined />}
                                                            style={{margin:'5px'}}
                                                        >
                                                            {t('course.screens.publishCode.buttonChange')}
                                                            <VisuallyHiddenInput
                                                                type="file"
                                                                onChange={(event) => {
                                                                    setSelectedFile(event.target.files[0]);
                                                                }}
                                                            />
                                                        </Button>
                                                    </ThemeProvider>
                                                    <ThemeProvider theme={theme}>
                                                        <Button
                                                            component="label"
                                                            role={undefined}
                                                            variant="contained"
                                                            tabIndex={-1}
                                                            startIcon={<SendOutlined />}
                                                            style={{margin:'5px'}}
                                                            onClick={() => {
                                                                uploadFile();
                                                            }}
                                                        >
                                                            {t('course.screens.publishCode.buttonSend')}
                                                        </Button>
                                                    </ThemeProvider>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    : <div>
                                        <ThemeProvider theme={theme}>
                                            <Button
                                                component="label"
                                                role={undefined}
                                                variant="contained"
                                                tabIndex={-1}
                                                startIcon={<CloudUploadOutlined />}
                                                disabled
                                            >
                                                {t('course.screens.publishCode.buttonText')}
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                }
                            </div>
                            <Divider style={{margin:'0px 5%'}} orientation='vertical' flexItem/>
                            <div style={{width:'45%'}}>
                                {publishInfo['intents'] === 0 || publishInfo['download'] === null
                                    ? <div style={{fontSize:'20px', fontWeight:'600'}}>
                                        {t('course.screens.publishCode.noIntents')}
                                    </div>
                                    : <div>
                                        <div>
                                            {t('course.screens.publishCode.lastIntent')}:
                                        </div>
                                        <div style={{height:'10px'}}/>
                                        <ThemeProvider theme={theme}>
                                            <Button
                                                component="label"
                                                role={undefined}
                                                variant="contained"
                                                tabIndex={-1}
                                                startIcon={<CloudDownloadOutlined />}
                                                onClick={async () => {
                                                    const url = await getDownloadURL(ref(storage, publishInfo['download']));

                                                    const xhr = new XMLHttpRequest();
                                                    xhr.responseType = 'blob';
                                                    xhr.onload = (event) => {
                                                        const blob = xhr.response;
                                                        save(blob, docName);
                                                    };
                                                    xhr.onprogress = e => console.log(`${parseInt((e.loaded/e.total)*100)}%`)
                                                    xhr.open('GET', url);
                                                    xhr.send();
                                                }}
                                            >
                                                {docName}
                                            </Button>
                                        </ThemeProvider>
                                        <div style={{height:'10px'}}/>
                                        <div>
                                            Nota: {publishInfo['nota'] === undefined || publishInfo['nota'] === null
                                                ? "Aún no hay nota"
                                                : publishInfo['nota']
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <LoadingBarApp/>
        }
    </div>;
}

export function DrawTopic( {topic, chapter, topicNum, saveProgress, saveCuestionario, firstIdx, lastIdx, courseId, userId, sendCuestionario, updateNotaTopic, nextTopic} ) {
    const stepsGen = [];

    if (topic.getVideo())
        stepsGen.push(new Steps("Mirar el video", "Mira este video con el contenido neceario para contestar el cuestionario", new Video(topic.getVideo(), null)));
        
    if (topic.getQuestionsRef() && topic.getQuestionsNum()) {
        stepsGen.push(new Steps("Cuestionario", "Responde y aprueba el cuestionario para poder seguir avanzando", new Cuestionario(topic.getQuestionsNum(), topic.getQuestionsRef(), null, topic.getAnswersRef(), null)));
        stepsGen.push(new Steps("Resultado final", "El resultado del último cuestionario es:", new Resultado(null)));
    }

    if (topic.getCodeIntents()) {
        stepsGen.push(new Steps("Sube tu código", "Escribe el código, comprueba que funcine correctmaente y subelo para que sea corregido", new CodePublish(topic.getCodeIntents(), topic.getCodeMax(), topic.getCodeRoute(), topic.getCodeBlock(), null)));
        stepsGen.push(new Steps("Resultado final", "El resultado del último intento es:", new PublishResult(null)));
    }

    if (topic.getProgress() === null) {
        const progress = {};
        stepsGen.forEach((step, index) => {
            progress[index] = false;
        })
        topic.updateProgress(progress);
    }

    console.warn("Generated Steps");
    console.log(stepsGen);

    var firstActive = 0;
    stepsGen.forEach((step, index) => {
        if (index>0 && topic.getProgress()[index-1] && !topic.getProgress()[index])
            firstActive = index;
    });

    const [steps, setSteps] = useState(stepsGen);
    const [activeStep, setActiveStep] = useState(firstActive);
    const [completed, setCompleted] = useState(topic.getProgress());
    const [fakeState, setFakeState] = useState(false);
    const [initated, setInitated] = useState(false);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        var save = false;

        console.log(`Handle complated: [${activeStep}] :: ${newCompleted[activeStep]} :: ${completed}`);

        if (!newCompleted[activeStep])
            save = true;

        newCompleted[activeStep] = true;
        setCompleted(newCompleted);

        if (save)
            saveProgress();

        handleNext();

    };

    const handleFinish = () => {
        const newCompleted = completed;
        var save = false;

        console.log(`Handle finish: [${activeStep}] :: ${newCompleted[activeStep]}`);

        if (!newCompleted[activeStep])
            save = true;

        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        if (save)
            saveProgress();

        nextTopic();
    }

    function setCurrentCuestionario(newCuestionario) {
        steps[activeStep].getContent().updateCurrentCuestionario(newCuestionario);
    }

    function setCorrectedCuestionarios(newCorrectedCuestionarios) {
        steps[activeStep].getContent().updateCorrectedCuestionarios(newCorrectedCuestionarios);
    }

    async function handleSaveCuestionario() {
        await saveCuestionario(steps[activeStep].getContent().getCurrentCuestgionario());
    }

    function handleThisComplated() {
        const newCompleted = completed;
        if (!newCompleted[activeStep]){
            newCompleted[activeStep] = true;
            saveProgress();
        }  
        setCompleted(newCompleted);
        const currrentActiveStep = activeStep;
    }

    function handleUpdateNota(nota) {
        updateNotaTopic(nota);
        setFakeState(!fakeState);
    }

    console.warn("Completed: ");
    console.log(completed);
    console.log(steps);

    return (
        <Box sx={{ width: '100%' }}>
            <ThemeProvider theme={secondaryTheme}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => {
                        return (
                        <Step key={label.getTitle()} completed={completed[index]}>
                            <StepButton disabled={!(completed[index] || (index>0 && completed[index-1]))} color="inherit" onClick={handleStep(index)}>
                            {label.getTitle()}
                            </StepButton>
                        </Step>
                        )})
                    }
                </Stepper>
            </ThemeProvider>
            <div>
                <Fragment>
                    <div style={{margin:'10px'}}>
                        <div>
                            {steps[activeStep].getDescription()}
                        </div>
                        {steps[activeStep].getContent() instanceof Video
                            ? <div style={{marginTop:'5px'}}>
                                <VideoViwer step={steps[activeStep].getContent()}/>
                            </div>
                            : steps[activeStep].getContent() instanceof Cuestionario
                                ? <div>
                                    <CuestionarioViwer step={steps[activeStep]} chapter={chapter} topic={topicNum} setCurrentCuestionario={setCurrentCuestionario} saveCuestionario={handleSaveCuestionario} firstIdx={firstIdx} lastIdx={lastIdx} userId={userId} sendCuestionario={sendCuestionario} setCorrectedCuestionarios={setCorrectedCuestionarios} updateNotaTopic={handleUpdateNota} setThisComplated={handleThisComplated}/>
                                </div>
                                : steps[activeStep].getContent() instanceof Resultado ? <div>
                                    <CircularProgressApp val={topic.getNota()}/>
                                </div> : steps[activeStep].getContent() instanceof CodePublish ? <div>
                                    <CodePublisViwer step={steps[activeStep]} firstIdx={firstIdx} lastIdx={lastIdx}/>
                                </div> : steps[activeStep].getContent() instanceof PublishResult ? <div>
                                    <CircularProgressApp val={49}/>
                                </div> : <div></div>
                            
                        }
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Anterior
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={activeStep === steps.length-1? handleFinish : handleComplete} sx={{ mr: 1 }} disabled={!(steps[activeStep].getContent() instanceof Video || (steps[activeStep].getContent() instanceof Cuestionario && (topic.getNota() !== null && topic.getNota() >= 50)) || (steps[activeStep].getContent() instanceof CodePublish) || activeStep === steps.length-1 || completed[activeStep])}>
                        {activeStep === steps.length-1? "Acabar" : "Siguiente"}
                    </Button>
                    </Box>
                </Fragment>
            </div>
        </Box>
    );
}

export function DocDownload({ doc }) {
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [type, setType] = useState(null);
    const [name, setName] = useState(null);

    async function getDownloadURLDoc() {
        const docRef = ref(storage, doc);
        const url = await getDownloadURL(docRef);
        const metadata = await getMetadata(docRef);
        setType(metadata.contentType);
        setName(metadata.name);
        setDownloadUrl(url);
    }

    if (downloadUrl === null) {
        getDownloadURLDoc();
    }

    return <div className='download-file'>
        {downloadUrl === null? <LoadingBarApp/> : <a style={{color:'#708090'}} href={downloadUrl} target="_blank">
            {type === "application/pdf"? <PictureAsPdfIcon sx={{ fontSize: 80 }}/> : <div></div>}
            <Divider style={{color:'black'}}/>
            <div className='doc-name' style={{color:'black'}}>
                {name}
            </div>
        </a>}
    </div>
}