import { useNavigate } from 'react-router-dom';
import '../style/forums.css';
import { User } from './user';
import { doc, getDoc } from 'firebase/firestore';
import { db, functions } from '../firebase/firebase';
import { PublicUser } from './publicUser';
import { Avatar, Button, Divider, Link, TextField } from '@mui/material';
import RespondButton from '../components/respond-btn';
import CloseIcon from '@mui/icons-material/Close';
import { httpsCallable } from 'firebase/functions';
import { reload } from 'firebase/auth';
import { Markup } from 'interweave';

export class Forum {
    constructor (id, title, type, number, welcome_msg, messages) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.number = number;
        this.welcome_msg = welcome_msg;
        this.messages = messages;
    }

    static async fromDatabase(doc) {
        const messages = [];

        console.log(doc);

        await Promise.all(doc.get('messages').map(async (message, index) => {
            console.log(index);
            console.log(message);
            const msg = await Message.fromDatabase(message, null, index);
            console.log(msg);
            messages[index] = msg;
        }));

        return new Forum(doc.id, doc.get('title'), doc.get('type'), doc.get('num'), doc.get('welcome_message'), messages);
    }

    getId() {
        return this.id;
    }

    getTitle() {
        return this.title;
    }

    getType() {
        return this.type;
    }

    getNumber() {
        return this.number;
    }

    getWelcomeMsg() {
        return this.welcome_msg;
    }

    getMessages() {
        return this.messages;
    }

    drawForumInfo(navigate, courseId) {
        return <div className='forum' onClick={() => {navigate(`/forums/${courseId}?forum=${this.id}`)}}>
            <div className='forum-name'>{this.getTitle()}</div>
            <div style={{marginLeft:'auto'}}>{this.getNumber()} {this.getNumber() === 1? " mensaje" : " mensajes"}</div>
        </div>;
    }
}

const avatarSize = '50px';

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function stringAvatar(name) {
    name = name.replace('_', ' ');
    name = name.replace('-', ' ');
    name = name.toUpperCase();

    if (name.split(' ').length > 1) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: avatarSize,
                height: avatarSize
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: avatarSize,
                height: avatarSize
            },
            children: `${name.split(' ')[0][0]}`,
        };
    }    
}

export class Message {
    constructor (message, user, userId, type, time, responds, id) {
        this.message = message;
        this.user = user;
        this.userId = userId;
        this.type = type;
        this.time = time;
        this.responds = responds;
        this.id = id;
    }

    static async fromDatabase(message, id, index) {
        const responds = [];

        if (id === null)
            id = "";

        const newId = `${id}-${index}`;

        await Promise.all(await message['responds'].map(async (newMessage, nIndex) => {
            responds[nIndex] = await Message.fromDatabase(newMessage, newId, nIndex);
        }));

        const userDoc = await getDoc(doc(db, 'publicUsers', message['user']));

        const user = PublicUser.fromDatabase(userDoc);

        return new Message(message['message'], user, message['user'], message['type'], message['time'], responds, newId);
    }

    getUser() {
        return this.user;
    }

    drawMessage(index, isPrincipal, handleRespond, sendMsg, setSendMsg, courseId, forumId, reload, canSend) {
        return <div key={index} className={`forum-message ${isPrincipal? ' principal':''}`} style={{marginLeft:`${(this.id.split('-').length-2)*30}px`, marginTop:isPrincipal?'0px':'20px'}}>
            <div className='forum-user'>
                <Avatar {...stringAvatar(this.user.getName())}/>
                <div style={{width:'5px'}}/>
                <div>
                    <Link className='user-link' href={this.type === 'teacher'
                        ? `/teacher/${this.userId}`
                        : `/u/${this.userId}`
                    }><div className='forum-username'>{this.user.getName()}</div></Link>
                    {this.type === 'teacher'
                        ? <div className='forum-teacher'>Profesor</div>
                        : <div/>
                    }
                </div>
            </div>
            <div className='forum-msg' style={{marginLeft:avatarSize}}>
                <div style={{textAlign:'justify', marginTop:'30px'}}>
                    <Markup content={this.message}/>
                </div>
                {sendMsg !== null && sendMsg[2] === this.id
                    ? <div/>
                    : <RespondButton outline={false} onClick={() => {handleRespond([this.user.getName(), this.message, this.id])}} disabled={!canSend}/>
                }
                {sendMsg !== null
                    ? this.id === sendMsg[2]
                        ? <Respond sendMsg={sendMsg} setSendMsg={setSendMsg}  courseId={courseId} forumId={forumId} reload={reload}/>
                        : <div/>
                    : <div/>
                }
            </div>
            <div className='forum-responses'>
                {this.responds.map((response, indexD) => {
                    console.log(`${index} :: ${indexD}`);
                    return response.drawMessage(indexD, false, handleRespond, sendMsg, setSendMsg, courseId, forumId, reload, canSend);
                })}
            </div>
        </div>
    }
}

export function Respond({ sendMsg, setSendMsg, courseId, forumId, reload }) {
    return <div className="forum-send-msg">
        <div style={{display:'flex', flexWrap:'wrap'}}>
            <div style={{fontSize:'20px', fontWeight:'700'}}>Nuevo mensaje</div>
            <div style={{marginLeft:'5px', fontSize:'18px', fontWeight:'500'}}>{sendMsg === -1
                ? ""
                : `> ${sendMsg[0]}`
            }</div>
            <div className="respond-close-btn" style={{marginLeft:'auto'}} onClick={() => {
                setSendMsg(null);
            }}><CloseIcon/></div>
        </div>
        <Divider/>
        <TextField id="new-message" variant="outlined" style={{width:'-webkit-fill-available', margin:'2px'}} rows={9} multiline/>
        <div style={{display:'flex'}}>
            <Button variant='outlined' style={{marginLeft:'auto', marginRight:'2px', marginTop:'2px'}} onClick={() => {
                const sendForum = httpsCallable(functions, 'sendForum');

                const msg = document.getElementById('new-message').value.split(/\r?\n/g);
                console.log(msg);
                var sendMsgText = "";

                msg.forEach(text => {
                    console.log(text);

                    if (text === "" || text.replace(" ", "") === ""){
                        sendMsgText = `${sendMsgText}<br>`;
                    } else {
                        sendMsgText = `${sendMsgText}<p>${text}</p>`;
                    }
                });

                console.log(sendMsgText);

                sendForum({
                    course: courseId,
                    forum: forumId,
                    msgUid: sendMsg[2],
                    msg: sendMsgText,
                }).then((result) => {
                    console.log(result);
                    
                    if (result.data) {
                        reload();
                    }
                });
            }}>Enviar</Button>
        </div>
    </div>;
}