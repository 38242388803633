import { Rating, Skeleton, Tooltip } from '@mui/material';
import '../style/course-card.css';
import { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';

export default function CourseViwerCard({ course, progress, user }) {
    const [state, setState] = useState(0);
    /*
      State:
      0: Loading additional data
      1: Data loaded
    */
    const [valorations, setValorations] = useState([
        0,0,0
    ]);

    const [progressNum, setCurrentProgress] = useState(0);
    const [initedProgress, setInitProgress] = useState(false);

    async function getProgress() {
        try {
            const docRef = await getDoc(doc(db, 'courses', course.getId(), 'progress', user.uid));

            console.log(docRef.id + " => " + docRef.get('progress'));
            course.updateProgress(docRef.get('progress')/course.getTotalCaps()*100);
            setInitProgress(true);
        } catch (e) {
            console.error(e);
        }
    }

    async function getData() {
        try {
            const docRef = await getDoc(doc(db, 'courses', course.getId(), 'valorations', 'info'));
            console.log(docRef.data());
            course.setValorationsNum(docRef.get('total'));
            course.setValoration(docRef.get('valoration'));

            setState(1);
            getProgress();
        } catch (e) {
            console.error(e);
        }
    }

    if (state === 0) {
        getData();
    }

    if (state === 1 && !initedProgress) {
        getProgress();
    }

    return <a style={{color:'black'}} href={'course/' + course.getId()}>
        <div className="course-card">
            <img src={course.getPhotoUrl()} style={{width:'100%'}}/>
            <div className='prior-info'>
                <div>
                    {course.getTitle()}
                </div>
                {state===0? <Skeleton variant='rectangular' width={90} height={30}/> : <div style={{marginLeft:'auto', textAlign:'end'}}>
                    <Rating sx={{}} readOnly name="half-rating" defaultValue={course.getValoration()} precision={0.25}/>
                    <div className='rating-data'><Tooltip title={"Número de valoraciones"}><div>{course.getValorationsNum()}</div></Tooltip>/<Tooltip title={"Número de alumnos que han cursado el curso"}><div>{course.getUsersNum()}</div></Tooltip></div>
                </div>}
            </div>
            <div className='additional-info'>
                <div style={{borderRight:'1px solid #bdbdbd', margin:1, marginLeft:3, marginTop:0, marginBottom:0, paddingRight:2, fontSize:13, paddingTop:5, paddingBottom:5}}>
                    {course.getLevelString()}
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                    {course.getLenguajeIcons()}
                </div>
                <div className='progress-div'>
                    {progress? course.getProgress() + "%" : <div></div>}
                    <div style={{margin:2, display:'flex', alignItems:'center'}}>
                        {course.getIdiomaIcon()}
                    </div>
                </div>
            </div>
        </div>
    </a>
}

export function CourseViwerCardTeacher({ course}) {
    const [state, setState] = useState(0);
    /*
      State:
      0: Loading additional data
      1: Data loaded
    */
    const [valorations, setValorations] = useState([
        0,0,0
    ]);

    async function getData() {
        try {
            const docRef = await getDoc(doc(db, 'courses', course.getId(), 'valorations', 'info'));
            console.log(docRef.data());
            course.setValorationsNum(docRef.get('total'));
            course.setValoration(docRef.get('valoration'));

            setState(1);
        } catch (e) {
            console.error(e);
        }
    }

    if (state === 0) {
        getData();
    }

    return <a style={{color:'black'}} href={'/teachers/course/' + course.getId()}>
        <div className="course-card">
            <img src={course.getPhotoUrl()} style={{width:'100%'}}/>
            <div className='prior-info'>
                <div>
                    {course.getTitle()}
                </div>
                {state===0? <Skeleton variant='rectangular' width={90} height={30}/> : <div style={{marginLeft:'auto', textAlign:'end'}}>
                    <Rating sx={{}} readOnly name="half-rating" defaultValue={course.getValoration()} precision={0.25}/>
                    <div className='rating-data'><Tooltip title={"Número de valoraciones"}><div>{course.getValorationsNum()}</div></Tooltip>/<Tooltip title={"Número de alumnos que han cursado el curso"}><div>{course.getUsersNum()}</div></Tooltip></div>
                </div>}
            </div>
            <div className='additional-info'>
                <div style={{borderRight:'1px solid #bdbdbd', margin:1, marginLeft:3, marginTop:0, marginBottom:0, paddingRight:2, fontSize:13, paddingTop:5, paddingBottom:5}}>
                    {course.getLevelString()}
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                    {course.getLenguajeIcons()}
                </div>
                <div className='progress-div'>
                    <div></div>
                    <div style={{margin:2, display:'flex', alignItems:'center'}}>
                        {course.getIdiomaIcon()}
                    </div>
                </div>
            </div>
        </div>
    </a>
}