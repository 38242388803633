import { Avatar } from "@mui/material";

const avatarSize = '50px';

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function stringAvatar(name) {
    name = name.replace('_', ' ');
    name = name.replace('-', ' ');
    name = name.toUpperCase();

    if (name.split(' ').length > 1) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: avatarSize,
                height: avatarSize
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: avatarSize,
                height: avatarSize
            },
            children: `${name.split(' ')[0][0]}`,
        };
    }    
}

export default function UserAvatar({ username, sx }) {
    return <Avatar {...stringAvatar(username)} style={sx}/>;
}