import { MenuItem, Select, Slider, Switch } from "@mui/material";
import { useState } from "react"
import { CheckBoxApp } from "./controlled-checkbox";
import { useTranslation } from "react-i18next";

export function FilterAge({ id, defaultValue, passFilters }) {
    const [age, setAge] = useState(defaultValue[1]);
    const [active, setActive] = useState(defaultValue[0]);

    var actualValue = 0;

    return <div>
        <div style={{display:'flex', alignItems:'center'}}>
            <div className="filter-title">Edad recomendada: </div>
            <Switch sx={{marginLeft:'auto'}} defaultChecked={defaultValue[0]} onChange={(event) => {
                setActive(event.target.checked);
                passFilters([event.target.checked, age]);
            }}/>
        </div>
        <div style={{marginLeft:10}}>
            <div style={{color: active? 'black' : 'grey'}}>{age} años</div>
            <Slider id={id} onChangeCommitted={(value) => {
                setAge(actualValue);
                passFilters([active, actualValue]);
            }} disabled={!active} getAriaValueText={(value) => {actualValue=value;}} defaultValue={defaultValue[1]} aria-label="Default" valueLabelDisplay="auto" />
        </div>
    </div>
}

export function FilterLevel({ defaultValue, passFilters, sx }) {
    const { t } = useTranslation();

    const levelTexts = {
        "Básico": t('browse.level.basic'),
        "Básico dedicado": t('browse.level.basicDedicated'),
        "Dedicado": t('browse.level.dedicated'),
        "Avanzado": t('browse.level.advanced')
    };

    const [level, setLevel] = useState(defaultValue[1]);
    const [active, setActive] = useState(defaultValue[0]);

    function handleCheckBoxChange(arrayIdx, value) {
        level[arrayIdx] = value;
        passFilters([active, level]);
    }

    function levelCheckbox() {
        var retVal = [];
        for (var item in level) {
            console.log(item);
            retVal.push(<CheckBoxApp disabled={!active} key={item} defaultChecked={level[item]} mapId={item} label={levelTexts[item]} handleChange={handleCheckBoxChange}/>);
        }
        return retVal;
    }

    return <div style={sx}>
        <div style={{display:'flex', alignItems:'center'}}>
            <div>{t('browse.level.title')}: </div>
            <Switch sx={{marginLeft:'auto'}} defaultChecked={defaultValue[0]} onChange={(event) => {
                setActive(event.target.checked);
                passFilters([event.target.checked, level]);
            }}/>
        </div>
        <div>
            {levelCheckbox()}
        </div>
    </div>;
}

export function FilterLan({ defaultValue, passFilters, sx }) {
    const { t } = useTranslation();

    const lanTexts = {
        "Español": t('browse.lan.esp'),
        "Catalán": t('browse.lan.cat')
    };

    const [lan, setLevel] = useState(defaultValue[1]);
    const [active, setActive] = useState(defaultValue[0]);

    function handleCheckBoxChange(arrayIdx, value) {
        lan[arrayIdx] = value;
        passFilters([active, lan]);
    }

    function levelCheckbox() {
        var retVal = [];
        for (var item in lan) {
            console.log(item);
            retVal.push(<CheckBoxApp disabled={!active} key={item} defaultChecked={lan[item]} mapId={item} label={lanTexts[item]} handleChange={handleCheckBoxChange}/>);
        }
        return retVal;
    }

    return <div style={sx}>
        <div style={{display:'flex', alignItems:'center'}}>
            <div>{t('browse.lan.title')}: </div>
            <Switch sx={{marginLeft:'auto'}} defaultChecked={defaultValue[0]} onChange={(event) => {
                setActive(event.target.checked);
                passFilters([event.target.checked, lan]);
            }}/>
        </div>
        <div>
            {levelCheckbox()}
        </div>
    </div>;
}

export function FilterLenguajes({ defaultValue, passFilters, sx }) {
    const { t } = useTranslation();

    const [lenguajes, setLevel] = useState(defaultValue[1]);
    const [active, setActive] = useState(defaultValue[0]);

    function handleCheckBoxChange(arrayIdx, value) {
        lenguajes[arrayIdx] = value;
        passFilters([active, lenguajes]);
    }

    function levelCheckbox() {
        var retVal = [];
        for (var item in lenguajes) {
            console.log(item);
            retVal.push(<CheckBoxApp disabled={!active} key={item} defaultChecked={lenguajes[item]} mapId={item} label={item} handleChange={handleCheckBoxChange}/>);
        }
        return retVal;
    }

    return <div style={sx}>
        <div style={{display:'flex', alignItems:'center'}}>
            <div>{t('browse.progLan.title')}: </div>
            <Switch sx={{marginLeft:'auto'}} defaultChecked={defaultValue[0]} onChange={(event) => {
                setActive(event.target.checked);
                passFilters([event.target.checked, lenguajes]);
            }}/>
        </div>
        <div>
            {levelCheckbox()}
        </div>
    </div>;
}