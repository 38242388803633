import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { auth, db } from "../firebase/firebase";

import '../style/style.css';
import '../style/payment.css';
import { useState } from "react";
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "react-responsive";

export class Payment {
    constructor (id, items, total) {
        this.id = id;
        this.items = items;
        this.total = total;
    }

    static async fromFirestoreLast(uid) {
        const docData = await getDocs(query(collection(db, 'customers', uid, 'payments'), orderBy('created', 'desc'), limit(1)));
        console.log(docData.docs[0].data());

        const items = [];

        if (docData.docs[0] && docData.docs[0].data() != undefined) {
            if (docData.docs[0].get('status') != "succeeded")
                return false;

            Promise.all(docData.docs[0].get('items').map(async itemData => {
                const item = await PayedItem.fromFirestore(itemData);

                console.log(item);
                items.push(item);
            }));

            return new Payment(docData.docs[0].id, items, docData.docs[0].get('amount'));
        } else {
            return null;
        }
    }

    getId() {
        return this.id;
    }

    getItems() {
        return this.items;
    }

    getTotal() {
        return this.total;
    }
}

export class PayedItem {
    constructor (id, name, price, totalPrice, totalRealPrice, discount, quantity, metadata) {
        this.id = id;
        this.name = name;
        this.price = price;
        this.totalPrice = totalPrice;
        this.totalRealPrice = totalRealPrice;
        this.discount = discount;
        this.quantity = quantity;
        this.metadata = metadata;
    }

    static async fromFirestore(itemData) {
        return new PayedItem(itemData['price']['product'], itemData['description'], itemData['price']['unit_amount'], itemData['amount_subtotal'], itemData['amount_total'], itemData['amount_discount'], itemData['quantity'], {type: undefined, description: undefined});
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getType() {
        return this.metadata['type'];
    }

    getTypeString() {
        switch (this.metadata['type']) {
            case "course":
                return "Curso";
            default:
                return "No hemos encontrado el tipo de producto";
        }
    }

    getDescription() {
        return this.metadata['description'];
    }

    getQuantity() {
        return this.quantity;
    }

    getPriceUnit() {
        return this.price;
    }

    getTotalPrice() {
        return this.totalPrice;
    }

    getDiscount() {
        return this.discount;
    }

    getRealPrice() {
        return this.totalRealPrice;
    }

    updateType(type) {
        this.metadata['type'] = type;
    }

    updateDescription(description) {
        this.metadata['description'] = description;
    }
}

export function DrawPaymentItem({ keyy, item }) {
    const middleScreen = useMediaQuery({
        query: '(min-width: 575px)'
    });

    const [photo, setPhoto] = useState(null);
    const [photoPendent, setPhotoPendent] = useState(false);
    const [typePendent, setTypePendent] = useState(false);
    const [isCourseSet, setIsCourseSet] = useState(null);

    async function getPhoto() {
        setPhotoPendent(true);
        
        if (item.getType() != undefined) {
            switch(item.getType()) {
                case false:
                    setPhoto(undefined);
                    break;
                case "course":
                    const courseData = await getDoc(doc(db, 'courses', item.getId()));
                    setPhoto(courseData.get('photo'));
                    break;
                default:
                    setPhoto(null);
                    break;
            }
        }
    }

    async function getType() {
        setTypePendent(true);

        try {
            const courseData = await getDoc(doc(db, 'courses', item.getId()));

            item.updateType("course");
            item.updateDescription(courseData.get('description'));

            setIsCourseSet(courseData.get('users').includes(auth.currentUser.uid));
        } catch (e) {
            item.updateType(false);
            item.updateDescription(false);
        }

        getPhoto();
    }
    
    if (photo === null && !photoPendent) {
        getPhoto();
    }
    if ((item.getType() === undefined || item.getDescription() === undefined) && !typePendent) {
        getType();
    }

    if (photo != null && item.getType() != undefined) {
        return <div className={`fontUbuntu item-card ${middleScreen? 'grid':''}`} key={keyy}>
            <img className="item-photo" src={photo}/>
            <div className="item-name">{item.getName()}</div>
            <div className="item-data">
                <div style={{fontWeight:'600'}}>{item.getTypeString()} {isCourseSet?"":"-- Se esta configurando su acceso al curso (recibirá un correo electrónico cuando tenga acceso)"}</div>
                <div style={{textAlign:'justify', paddingBottom:'5px'}}>{item.getDescription() === false
                        ? "Descripción no encontrado"
                        : item.getDescription()
                    }</div>
            </div>
            <div className="item-qnt" style={{fontSize:middleScreen?'':'5vw'}}>X{item.getQuantity()}</div>
            <div className="item-prices">
                <div className="separated-field">Por ud.: {(item.getPriceUnit()/100).toFixed(2)}€</div>
                <div className="separated-field">Total: {(item.getTotalPrice()/100).toFixed(2)}€</div>
                <div className="separated-field">Descuento: {(item.getDiscount()/100).toFixed(2)}€</div>
                <div className="separated-field" style={{fontWeight:'600'}}>Total con desc.: {(item.getRealPrice()/100).toFixed(2)}€</div>
            </div>
        </div>;
    }
    
    return <div className="fontUbuntu item-card" key={keyy}><Skeleton variant="rounded" height="100px"/></div>;
}