import { Link } from 'react-router-dom'
import '../style/not-found.css'

export default function NotFound() {
    return <div style={{textAlign:'center'}}>
        <h1 className="title">404</h1>
        <h2 className='subtitle'>Página no encontrada</h2>
        <div style={{height:50}}/>
        <div className='center'>
            Enlaces de interes:
            <Link className='link' to="/">Inicio</Link>
            <Link className='link' to="/dashboard/account">Cuenta</Link>
            <Link className='link' to="/browse">Explorar</Link>
        </div>
    </div>
}