import { onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { auth, userExists } from "../firebase/firebase";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import { Alert, Button, CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, ThemeProvider, createTheme } from "@mui/material";
import '../style/login.css';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import googleIcon from "../assets/icons/auth/google.png";
import logo from "../assets/images/LogoBM_Full.png";
import { useMediaQuery } from "react-responsive";

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFF'
        }
    }
});

export default function LoginView() {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setCurrentState] = useState(0);
    const [validated, setValidated] = useState(false);
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(searchParams.get('redirect'));

    const [noti, setNoti] = useState('');

    const isLaptop = useMediaQuery({
        query: '(min-width: 630px)'
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/;

    useEffect(() => {
        setCurrentState(1);
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const exists = await userExists(user.uid);
                if (exists) {
                    console.log(`User logged in!!! ${redirect}`);
                    console.log(redirect);

                    if (redirect !== null) {
                        navigate(redirect);
                    } else {
                        navigate("/");
                    }
                    setCurrentState(2);
                } else {
                    navigate("/dashboard/account");
                    setCurrentState(3);
                }
    
                console.log(user.displayName);
            } else {
                setCurrentState(4);
                console.log("Nadie autenticado...");
            }
        });
    }, [navigate]);

    async function handleOnClick() {
        console.warn("Entrar con Google estará desabilitado hasta la apertura oficial de BitMinds Acadaemy.")
        //const googleProvider = new GoogleAuthProvider();
        //await signinGoole(googleProvider);
    }

    async function signinGoole(googleProvider) {
        try {
            const res = await signInWithPopup(auth, googleProvider);
            console.log(res);
        } catch (e) {
            console.error(e);
        }
    }

    function handleOnUserLoggedIn(user) {
        navigate("/");
    }

    function handleOnUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleOnUserNotLoggedIn() {
        setCurrentState(4);
    }

    function validateMail(mailStr) {
        if (mailStr) {
            return mailRegex.test(mailStr);
        } else {
            return mailRegex.test(mail);
        }
    }

    function validatePassword(passwordStr) {
        if (passwordStr) {
            return passwordRegex.test(passwordStr);
        } else {
            return passwordRegex.test(password);
        }
    }

    const handleEnterBtn = async () => {
        setMail(document.getElementById('mail-field').value);
        setPassword(document.getElementById('password-field').value);

        setValidated(true);

        if (validateMail(document.getElementById('mail-field').value) && validatePassword(document.getElementById('password-field').value)) {
            setLoading(true);
            try {
                const userCredential = await signInWithEmailAndPassword(auth, document.getElementById('mail-field').value, document.getElementById('password-field').value);
            } catch (e) {
                console.log(e);

                switch(e.code) {
                    case "auth/invalid-credential":
                        setNoti('El correo electrónico o la contraseña no son correctos.')
                }
            }
            setLoading(false);
        }
    }

    if (state === 4) {
        return <div style={{height:'100%', marginLeft: '5px', marginRight:'5px'}}>
            {isLaptop? <div style={{display:'flex'}}>
                    <img className="page-logo" src={logo}/> 
                </div> : <div></div>}
            <div style={{display:'flex'}}>
                <div className="login-form-card">
                    <div style={{display:'flex'}}>
                        <div className="login-title">
                            ENTRAR
                        </div>
                    </div>
                    <Divider sx={{margin:1}}/>
                    {noti!==''? <Alert className="error-box" severity="error">{noti}</Alert> : <div></div>}
                    <div className="form-login-card">
                        <TextField
                            type="mail"
                            style={{ width:'100%', marginTop:5, marginBottom:5}}
                            id="mail-field"
                            label="E-Mail"
                            variant="outlined"
                            defaultValue={''}
                            error={!validateMail() && validated}
                            helperText={validated&&!validateMail()? "Introduzca un correo electrónico que sea válido": null}
                        />
                        <FormControl sx={{width:'100%', marginTop:'5px', marginBottom:'5px'}}>
                            <InputLabel error={!validatePassword() && validated} htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                error={!validatePassword() && validated}
                                id="password-field"
                                type={showPassword ? 'text' : 'password'}
                                sx={{width:'100%'}}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password"
                            />
                            {validated&&!validatePassword()? <FormHelperText error={!validatePassword() && validated} id="outlined-weight-helper-text">{<div style={{width:''}}>
                                    <div>Introduzca una contraseña que cumpla con las sigueintes normas:</div>
                                    <ol>
                                        <li>Debe contener letras en minúsculas y mayusculas</li>
                                        <li>Debe incluir números</li>
                                        <li>Debe incluir símbolos</li>
                                        <li>Debe tener 10 caracteres mínimo</li>
                                    </ol>
                                </div>}
                            </FormHelperText> : <div></div>}
                        </FormControl>
                        <Button sx={{width:'100%', marginTop:'5px'}} disabled={loading} onClick={handleEnterBtn} variant="outlined">{!loading? "Entrar" : <CircularProgress/>}</Button>
                    </div>
                    <Divider sx={{margin: 1}}/>
                    <div style={{textAlign: 'center', margin: '5px'}}>
                        <div className="options-text">¿Te has olbidado de la contraseña? <span className="options-text" onClick={() => {navigate('/recoverPassword')}}>Recuperala</span></div>
                        <div style={{height:'10px'}}/>
                        <div className="options-text">¿Aún no tienes una cuenta? <span className="options-text">Registrate</span></div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex'}}>
                <div className="divider-o">
                    <div className="divider-in-flex" style={{marginRight: '7px'}}><Divider/></div>
                    <div>O</div>
                    <div className="divider-in-flex" style={{marginLeft: '7px'}}><Divider/></div>
                </div>
            </div>
            <div style={{display:'flex', marginTop:0, justifyContent:'center'}} className="divider-o">
                <div style={{display:'flex', flexFlow: 'row wrap', justifyContent:'center'}}>
                    <div>
                        <div onClick={handleOnClick} className='g-sign-in-button'>
                            <div className="content-wrapper">
                                <div className='logo-wrapper'>
                                    <img src={googleIcon}/>
                                </div>
                                <span className='text-container'>
                                    <span>Entrar con Google</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    return <AuthProvider 
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotLoggedIn={handleOnUserNotLoggedIn}
      onUserNotRegistered={handleOnUserNotRegistered}>
        <LoadingBarApp/>
    </AuthProvider>
}