import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PublicCapView({num, title, description, last}) {
    console.log("Title: " + title);
    return <Accordion className={last? "course-cap-dropdouwn" : ""}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
        >
            <div style={{fontSize:16, fontWeight:600}}>{num + ": " + title}</div>
        </AccordionSummary>
        <AccordionDetails>
            <div style={{fontSize:14}}>{description}</div>
        </AccordionDetails>
    </Accordion>;
}