import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import AuthProvider from "../components/auth-provider";
import Navbar from "../components/navbar";
import LoadingBarApp from "../components/loading";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db, functions, storage } from "../firebase/firebase";
import { Chapter, Course, CurrentCuestionario, DocDownload, DrawTopic, PrivateCourse } from "../implementation/course";
import "../style/course-view.css";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Drawer, IconButton, Modal, Rating, Skeleton, Tab, Tabs, ThemeProvider, Tooltip, Typography, createTheme } from "@mui/material";
import PublicCapView from "../components/public-capitol-view";
import { useMediaQuery } from "react-responsive";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PublicIcon from '@mui/icons-material/Public';
import BookIcon from '@mui/icons-material/Book';
import ReactPlayer from "react-player";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import VideoPlayerApp from "../components/video-player";
import { Markup } from 'interweave';
import ArticleIcon from '@mui/icons-material/Article';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import CourseBtn from "../components/course-tema-btn";
import CourseDrawer, { CourseDrawerOther } from "../components/course-drawer";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTranslation } from "react-i18next";
import { httpsCallable } from "firebase/functions";
import { CustomDividerVertical, RoundButton } from "../components/customUi";
import { AddShoppingCartOutlined, DownloadOutlined, OpenInNew, PaymentsOutlined } from "@mui/icons-material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#333"
        }
    }
});

const cuestionarioConverter = {
    toFirestore: (cuestionario) => {
        const questions = [];

        cuestionario.getQuestions().forEach(question => {
            questions.push({
                'id': question.getId(),
                'respond': question.getRespond()
            });
        });

        return {
            'questions': questions
        };
    },
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, key, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        key={key}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

export default function CoursePage() {
    const { t } = useTranslation();
    
    const {courseId} = useParams();
    const navigate = useNavigate();
    const isLaptop = useMediaQuery({
        query: '(min-width: 1000px)'
    });
    const middleScreen = useMediaQuery({
        query: '(min-width: 720px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 530px)'
    });
    const suficientDrawer = useMediaQuery({
        query: '(min-width: 460px)'
    });

    const [state, setCurrentState] = useState(0);
    /*
      0: Loading
      1: 
    */
    const [userr, setUser] = useState(null);
    const [course, setCourse] = useState(null);
    const [rating, setRating] = useState(false);
    const [page, setCurrentPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedPart, setSelectedPart] = useState([0,-1])

    const [fakeState, setFakeState] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);

    const [posisionated, setPosisionated] = useState(false);

    const [value, setValue] = useState(0);

    const [activeCart, setActiveCart] = useState(null);
    const [activeInCart, setActiveInCart] = useState(null);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate(`/login?redirect=/course/${courseId}`);
    }

    async function getData() {
        try {
        const docRef = await getDoc(doc(db, 'courses', courseId));
        console.log(docRef.data());

        var privateCourseData = null;
        try {
            const privateCourse = await getDocs(collection(db, 'courses', courseId, 'chapters'));
            const chapters = [];
            privateCourse.forEach(item => {
                console.log(item.id + " :: " + item.get('title'));
                chapters.push(Chapter.fromDataBase(item));
            });
            switch (docRef.get('idioma')) {
                case "ESP":
                    chapters.push(new Chapter("Finalización", "Finalización y revisión del curso.", [], null, null, null, false, false));
                    break;
                case "CAT":
                    chapters.push(new Chapter("Finalització", "Finalització i revisió del curs.", [], null, null, null, false, false));
                    break;
            }
            

            console.log(chapters);
            privateCourseData = new PrivateCourse(chapters);
        } catch (e) {
            console.log(e);
        }

        const courseData = Course.fromDataBase(docRef);
        courseData.updatePrivateCourse(privateCourseData);

            try {
                const priceRef = await getDocs(query(collection(db, 'products', courseData.getId(), 'prices'), where('active', '==', true)));
                courseData.updatePrice(priceRef.docs[0].get('unit_amount'));
                if (priceRef.docs[1])
                    courseData.updateSecondPrice(priceRef.docs[1].get('unit_amount'));
            } catch (e) {
                console.error(e);
            }

            if (privateCourseData != null) {
                console.log(privateCourseData);

                console.log("Getting progress:: ");
                try {
                    const progress = await getDoc(doc(db, 'courses', courseId, 'progress', userr.uid));
                    console.log("Progress readed: ");
                    console.log(progress.data());

                    if (progress.data()) {
                        console.log("There is progress");
                    } else {
                        console.log("there is no progress");
                    }
                    const progressArray = progress.get('progress');

                    if (progressArray) {
                        courseData.getPrivateData().getChapters().forEach((chapter, index) => {
                            console.log(chapter);
                            console.log(index);

                            var complated = false;
                            if (progressArray[index]) {
                                complated = progressArray[index]['completed'];
                            }
                            chapter.updateComplated(complated);

                            if (progressArray[index]) {
                                const topicsProgressArray = progressArray[index]['apartados'];
                                var videoComplated = false;
                                if (topicsProgressArray[0]) {
                                    videoComplated = topicsProgressArray[0]['completed'];
                                }
                                chapter.updateVideoComplated(videoComplated);

                                chapter.getTopics().forEach((topic, i) => {
                                    var topicCompleted = false;
                                    var progressTopic = null;
                                    var notaTopic = null;
                                    if (topicsProgressArray[i+1]){
                                        topicCompleted = topicsProgressArray[i+1]['completed'];

                                        if (topicsProgressArray[i+1]['steps']) {
                                            progressTopic = topicsProgressArray[i+1]['steps'];
                                        }

                                        if(topicsProgressArray[i+1]['nota']) {
                                            notaTopic = topicsProgressArray[i+1]['nota'];
                                        }
                                    }
                                    topic.updateComplated(topicCompleted);
                                    topic.updateProgress(progressTopic);
                                    topic.updateNota(notaTopic);
                                });
                            } else {
                                chapter.updateVideoComplated(false);
                                chapter.getTopics().forEach((topic, i) => {
                                    topic.updateComplated(false);
                                });
                            }
                        });
                    } else {
                        courseData.getPrivateData().getChapters().forEach((chapter, index) => {
                            console.log(chapter);
                            console.log(index);

                            chapter.updateComplated(false);
                            chapter.updateVideoComplated(false);
                            chapter.getTopics().forEach((topic, i) => {
                                topic.updateComplated(false);
                            });
                        });
                    }



                    console.log("Pogress doc: ");
                    console.log(progress.data());
                } catch (e) {
                    console.error(e);
                    console.error("There is no progress");
                }

                setCurrentPage(1);
            }

            console.log(courseData);
            
            setCourse(courseData);
        } catch(e) {
            console.error(e);
        }
    }

    async function getRating() {
        try {
            const docRef = await getDoc(doc(db, 'courses', course.getId(), 'valorations', 'info'));
            console.log(docRef.data());
            course.setValorationsNum(docRef.get('total'));
            course.setValoration(docRef.get('valoration'));

            setRating(true);
        } catch (e) {
            console.error(e);
        }
    }

    async function payCourse() {
        handleOpen();

        try {
            const payNow = httpsCallable(functions, 'payNow');
            payNow({
                courseId: courseId
            }).then((result) => {
                console.log(result.data);

                const waitPaymentUrl = onSnapshot(doc(db, 'customers', userr.uid, 'checkout_sessions', result.data), (doc) => {
                    if (doc.get('url')) {
                        console.log(doc.get('url'));
                        window.location.href = doc.get('url');
                    }
                });
            });
        } catch (e) {
            console.error(e);
        }
    }

    const handlePay = () => {
        payCourse()
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
    }

    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen);
    };

    async function getVideoUrl(place) {
        try {
            const url = getDownloadURL(ref(storage, place));
            return url;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    function handleChangeView(i, ti) {
        console.log("Indexes: " + i + ", " + ti);
        console.log(course);
        setSelectedPart([i, ti]);
        setValue(ti);
        closeDrawer();
    }

    async function saveProgress() {
        const progressSave = [];

        console.log(course.getPrivateData().getChapters());
        course.getPrivateData().getChapters().map(chapter => {
            console.log(chapter);
            const topicsProgress = [];
            topicsProgress.push({
                "completed": chapter.getVideoComplated(),
                "type": "video"
            });
            chapter.getTopics().map(topic => {
                topicsProgress.push({
                    "completed": topic.getComplated(),
                    "type": "steps",
                    "steps": topic.getProgress(),
                    'nota': topic.getNota()
                });
            });
            progressSave.push({
                'apartados': topicsProgress,
                'completed': chapter.getComplated()
            })
        });

        console.log("Saved progress: ");
        console.warn(progressSave);

        const respond = await setDoc(doc(db, 'courses', courseId, 'progress', userr.uid), {
            'progress': progressSave
        });

        console.log('Saved progress: ');
        console.log(respond);
    }

    async function saveCuestionarioDataBase(cuestionario) {
        const cuestionarioId = 'cuestionario'+selectedPart[0]+(value-1);
        console.log('courses/' + courseId + '/progress/' + userr.uid + '/cuestionarios/' + cuestionarioId);
        const response = await setDoc(doc(db, 'courses', courseId, 'progress', userr.uid, 'cuestionarios', cuestionarioId).withConverter(cuestionarioConverter), cuestionario);

        await saveProgress();
    }

    async function sendCuestionario(currentCuestionario) {
        try {
            console.log(currentCuestionario);
            const docsRef = await getDocs(collection(db, 'courses', courseId, 'progress', userr.uid, 'finishedCuestionarios'));
            console.log(docsRef.docs.length !== 0? "hay documentos" : "no hay documentos");

            var intent = 0;
            if (docsRef.docs.length !== 0) {
                await docsRef.docs.forEach(docRef => {
                    if (docRef.id.startsWith(''+selectedPart[0]+(value-1))){
                        console.log(docRef.id);
                        intent++;
                    }
                });
            }

            const respond = await setDoc(doc(db, 'courses', courseId, 'progress', userr.uid, 'finishedCuestionarios', ''+selectedPart[0]+(value-1)+intent).withConverter(cuestionarioConverter), currentCuestionario);
            const remove = await deleteDoc(doc(db, 'courses', courseId, 'progress', userr.uid, 'cuestionarios', 'cuestionario'+selectedPart[0]+(value-1)));

            console.log("Cuestionario sended");
        } catch (e) {
            console.error(e);
        }
    }

    function handleUpdateNotaTopic(nota) {
        course.getPrivateData().getChapterAt(selectedPart[0]).getTopicAt(value-1).updateNota(nota);

        saveProgress();
    }

    function handleNextTopic() {
        if (!course.getPrivateData().getChapterAt(selectedPart[0]).getTopicAt(value-1).getComplated()){
            course.getPrivateData().getChapterAt(selectedPart[0]).getTopicAt(value-1).updateComplated(true);
            saveProgress();
        }

        if (value-1 === course.getPrivateData().getChapterAt(selectedPart[0]).getTopics().length-1) {
            if (!(selectedPart[0] === course.getPrivateData().getChapters().length-1)) {
                course.getPrivateData().getChapterAt(selectedPart[0]).updateComplated(true);
                console.error("New chapter is: " + (selectedPart[0]+1 === course.getPrivateData().getChapters().length-1));
                selectedPart[0] = selectedPart[0]+1;
                selectedPart[1] = -1;
                setValue(0);
            }
        } else {
            setValue(value+1);
        }

        setFakeState(!fakeState);
        saveProgress();
    }

    async function handleOnCart(cart) {
        const isInCart = await inCart(cart, courseId);
        console.log(`The current coourse is in cart: ${isInCart}`);
        setActiveInCart(isInCart);
        setActiveCart(cart);
    }

    async function inCart(cart, id) {
        var retVal = false;

        await Promise.all(cart.map(item => {
            console.log(`Item in cart: ${item['id']}`);
            if (item['id'] === id) {
                console.log("Is in cart");
                retVal = true;
            }
        }));

        return retVal;
    }

    function addToCart() {
        if (!activeInCart) {
            activeCart.push({'id':courseId, 'qnt':1})

            updateDoc(doc(db, 'users', userr.uid), {
                "carrito": activeCart,
            });

            setActiveCart(null);
        }
    }

    var index = 0;

    if (state === 1) {
        if (course === null) {
            getData();
        } else if (course !== null && !rating) {
            getRating();
        }

        if (course !== null && course.getPrivateData()) {
            if (!posisionated) {
                if (!course.getPrivateData().getChapterAt(0).getComplated()) {
                    if (!course.getPrivateData().getChapterAt(0).getVideoComplated()) {
                        setSelectedPart([0, -1]);
                        setValue(0);
                    } else {
                        var selectedTopic = null;
                        course.getPrivateData().getChapterAt(0).getTopics().forEach((topic, index) => {
                            if (!topic.getComplated() && selectedTopic === null) {
                                selectedTopic = index;
                            }
                        });

                        if (selectedTopic === null)
                            selectedTopic = 0;

                        setSelectedPart([0, selectedTopic]);
                        setValue(selectedTopic);
                    }
                } else {
                    var selectedChapter = null;
                    var selectedTopic = null;
                    course.getPrivateData().getChapters().forEach((chapter, index) => {
                        if (!chapter.getComplated() && selectedChapter === null) {
                            selectedChapter = index;

                            if (!chapter.getVideoComplated()) {
                                selectedTopic = -1;
                            } else {
                                chapter.getTopics().forEach((topic, tIndex) => {
                                    if (!topic.getComplated() && selectedTopic === null) {
                                        selectedTopic = tIndex;
                                    }
                                });
                            }
                        }
                    });
                    setSelectedPart([selectedChapter, selectedTopic]);
                    setValue(selectedTopic+1);
                }

                setPosisionated(true);
            }

            if (value === 0 && !(selectedPart[0] === course.getPrivateData().getChapters().length-1)) {
                if (course.getPrivateData().getChapterAt(selectedPart[0]).getVideoUrl() === null) {
                    getVideoUrl(course.getPrivateData().getChapterAt(selectedPart[0]).getVideo()).then(newUrl => {
                        console.log("New URL: " + newUrl);
                        course.getPrivateData().getChapterAt(selectedPart[0]).updateVideoUrl(newUrl);
                        setFakeState(!fakeState);
                    })
                }
            }
        }

        var capIndex = 0;

        return <div style={{height:'100%'}}>
            <Navbar userId={userr.uid} drawer={!isLaptop && page!==0} toggleDrawer={toggleDrawer} drawerIcon={<ArticleIcon fontSize="large" color="primary"/>} handleOnCart={handleOnCart}/>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <ThemeProvider theme={theme}>
                        <Box sx={{
                            position:'absolute', 
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            backgroundColor:'transparent'
                        }}><CircularProgress sx={{
                            display:'block'
                        }} size={'large'} color="primary"/></Box>
                    </ThemeProvider>
                </div>
            </Modal>
            {course === null? <div><div style={{height:'64px'}}/><LoadingBarApp/></div> : <div style={{height:'100%'}}>{page === 0? <div>
                <div style={{height:'64px', backgroundColor: '#708090'}}/>
                {isLaptop
                    ? <div className="carousel-course-region">
                        <div className="carousel-course-presentation">
                            <div style={{fontSize:'20px'}}>Curso de</div>
                            <div>{course.getTitle()}</div>
                            <div style={{fontSize:'20px'}}>para niñas y niños de 12 a 18 años</div>
                            <div style={{height:'30px'}}/>
                            <div className="carousel-extra-info">
                                <div className="data-part">
                                    <div className="important-text">{course.getTotalHours()}</div>
                                    <div>horas de videos de aprendizage</div>
                                </div>
                                <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                <div className="data-part">
                                    <div className="important-text">{course.getTotalExercices()}</div>
                                    <div>ejercicios de autoaprendizage</div>
                                </div>
                                <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                <div className="data-part">
                                    <div className="important-text">{course.getTotalPages()}</div>
                                    <div>paginas DIN-A4 de aprendizaje</div>
                                </div>
                            </div>
                            <div style={{height:'40px'}}/>
                            <div className={isLaptop? "course-info" : "course-info-mobile"}>
                                <Divider/>
                                <div style={{height:10}}/>
                                <div className="nivel-valoration">
                                    <div style={{fontSize: 14, alignContent:'center'}}>
                                        {t('course.level')}: {t(course.getLevelString())}
                                    </div>
                                    <div style={{marginLeft: 'auto'}}>{!rating? <Skeleton sx={{borderRadius:2}} variant='rectangular' width={90} height={30}/> : <div style={{marginLeft:'auto', textAlign:'end'}}>
                                        <Tooltip className="rating" title={course.getValoration()+"/5"}><div><Rating sx={{}} readOnly name="half-rating" defaultValue={course.getValoration()} precision={0.25}/></div></Tooltip>
                                        <div className='rating-data'><Tooltip className="rating-data-hover" title={t('course.tooltip.valorationsNum')}><div>{course.getValorationsNum()}</div></Tooltip>/<Tooltip className="rating-data-hover" title={t('course.tooltip.studentsNum')}><div>{course.getUsersNum()}</div></Tooltip></div>
                                    </div>}</div>
                                </div>
                                <div>
                                    <div className="idioma-course">
                                        {course.getIdiomaIcon()}
                                        <div style={{width:5}}/>
                                        <Divider orientation="vertical" flexItem />
                                        {course.getLenguajeIcons()}
                                    </div>
                                </div>
                                <div style={{height:10}}/>
                                <div style={{display:'flex'}}>
                                    <div style={{marginLeft:'auto', paddingRight:'3px'}}>
                                        <div style={{textDecoration:course.getSecondPrice()? 'line-through' : null, textAlign:'end'}}>
                                            {(course.getPrice()/100).toFixed(2)}€
                                        </div>
                                        {course.getSecondPrice()
                                            ? <div style={{fontSize: '20px', display:'flex', alignItems:'center'}}>
                                                <div style={{borderRadius:'6px', marginRight: '5px', backgroundColor:'red', padding:'3px'}}>
                                                    -{(((course.getPrice()/100)-(course.getSecondPrice()/100))/(course.getPrice()/100)*100).toFixed(2)}%
                                                </div>
                                                <div>
                                                    {(course.getSecondPrice()/100).toFixed(2)}€
                                                </div>
                                            </div>
                                            : <div></div>
                                        }
                                    </div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div>
                                    {course.getPrice()
                                        ? <ThemeProvider theme={createTheme({
                                            palette: {
                                                primary: {
                                                    main: "#36454f"
                                                }
                                            }
                                        })}>
                                            {course.buyedCourse()
                                                ? <div style={{display:'flex', justifyContent:'center', width:'-webkit-fill-available'}}>
                                                    <RoundButton text={"Entrar al curso"} onClick={() => {
                                                        setCurrentPage(1);
                                                    }} endIcon={<OpenInNew/>}/>
                                                </div>
                                                : <div style={{display:'flex', justifyContent:'center', width:'-webkit-fill-available'}}>
                                                    <RoundButton text={t('course.buyNow')} onClick={course.buyedCourse()? null: handlePay} endIcon={<PaymentsOutlined/>}/>
                                                    <div style={{width:'5%'}}/>
                                                    <RoundButton text="Añadir al carrito" onClick={() => {addToCart()}} endIcon={<AddShoppingCartOutlined/>} disabled={activeCart===null || (activeCart!==null && activeInCart)}/>
                                                </div>
                                            }
                                        </ThemeProvider>
                                        : <Skeleton sx={{borderRadius:2}} variant='rectangular' width={90} height={40}/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{marginLeft:'auto', width:'50%'}}>
                            <img src={course.getPhotoUrl()} width={'100%'} className="carousel-presentation-img"/>
                        </div>
                    </div>
                    : <div className="carousel-course-region no-flex">
                        <img src={course.getPhotoUrl()} width={'100%'} className="carousel-presentation-img no-flex"/>
                        <div className="carousel-course-presentation no-flex" style={smallScreen
                        ? {}
                        : {padding:'40px 50px'}}>
                            <div style={{fontSize:'20px'}}>Curso de</div>
                            <div>{course.getTitle()}</div>
                            <div style={{fontSize:'20px'}}>para niñas y niños de 12 a 18 años</div>
                            <div style={{height:'30px'}}/>
                            {middleScreen
                                ? <div className="carousel-extra-info">
                                    <div className="data-part">
                                        <div className="important-text">{course.getTotalHours()}</div>
                                        <div>horas de videos de aprendizage</div>
                                    </div>
                                    <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                    <div className="data-part">
                                        <div className="important-text">{course.getTotalExercices()}</div>
                                        <div>ejercicios de autoaprendizage</div>
                                    </div>
                                    <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                    <div className="data-part">
                                        <div className="important-text">{course.getTotalPages()}</div>
                                        <div>paginas DIN-A4 de aprendizaje</div>
                                    </div>
                                </div>
                                : <div className="carousel-extra-info no-flex">
                                    <div className="data-part no-flex">
                                        <div className="important-text">{course.getTotalHours()}</div>
                                        <div>horas de videos de aprendizage</div>
                                    </div>
                                    <div style={{height:'40px'}}/>
                                    <div className="data-part no-flex">
                                        <div className="important-text">{course.getTotalExercices()}</div>
                                        <div>ejercicios de autoaprendizage</div>
                                    </div>
                                    <div style={{height:'40px'}}/>
                                    <div className="data-part no-flex">
                                        <div className="important-text">{course.getTotalPages()}</div>
                                        <div>paginas DIN-A4 de aprendizaje</div>
                                    </div>
                                </div>
                            }
                            <div style={{height:'40px'}}/>
                            <div className={isLaptop? "course-info" : "course-info-mobile"}>
                                <Divider/>
                                <div style={{height:10}}/>
                                <div className="nivel-valoration">
                                    <div style={{fontSize: 14, alignContent:'center'}}>
                                        {t('course.level')}: {t(course.getLevelString())}
                                    </div>
                                    <div style={{marginLeft: 'auto'}}>{!rating? <Skeleton sx={{borderRadius:2}} variant='rectangular' width={90} height={30}/> : <div style={{marginLeft:'auto', textAlign:'end'}}>
                                        <Tooltip className="rating" title={course.getValoration()+"/5"}><div><Rating sx={{}} readOnly name="half-rating" defaultValue={course.getValoration()} precision={0.25}/></div></Tooltip>
                                        <div className='rating-data'><Tooltip className="rating-data-hover" title={t('course.tooltip.valorationsNum')}><div>{course.getValorationsNum()}</div></Tooltip>/<Tooltip className="rating-data-hover" title={t('course.tooltip.studentsNum')}><div>{course.getUsersNum()}</div></Tooltip></div>
                                    </div>}</div>
                                </div>
                                <div>
                                    <div className="idioma-course">
                                        {course.getIdiomaIcon()}
                                        <div style={{width:5}}/>
                                        <Divider orientation="vertical" flexItem />
                                        {course.getLenguajeIcons()}
                                    </div>
                                </div>
                                <div style={{height:10}}/>
                                <div style={{display:'flex'}}>
                                    <div style={{marginLeft:'auto', paddingRight:'3px'}}>
                                        <div style={{textDecoration:course.getSecondPrice()? 'line-through' : null, textAlign:'end'}}>
                                            {(course.getPrice()/100).toFixed(2)}€
                                        </div>
                                        {course.getSecondPrice()
                                            ? <div style={{fontSize: '20px', display:'flex', alignItems:'center'}}>
                                                <div style={{borderRadius:'6px', marginRight: '5px', backgroundColor:'red', padding:'3px'}}>
                                                    -{(((course.getPrice()/100)-(course.getSecondPrice()/100))/(course.getPrice()/100)*100).toFixed(2)}%
                                                </div>
                                                <div>
                                                    {(course.getSecondPrice()/100).toFixed(2)}€
                                                </div>
                                            </div>
                                            : <div></div>
                                        }
                                    </div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div>
                                    {course.getPrice()
                                        ? <ThemeProvider theme={createTheme({
                                            palette: {
                                                primary: {
                                                    main: "#36454f"
                                                }
                                            }
                                        })}>
                                            {course.buyedCourse()
                                                ? <div style={{display:'flex', justifyContent:'center', width:'-webkit-fill-available'}}>
                                                    <RoundButton text={"Entrar al curso"} onClick={() => {
                                                        setCurrentPage(1);
                                                    }} endIcon={<OpenInNew/>}/>
                                                </div>
                                                : <div style={{width:'-webkit-fill-available'}}>
                                                    <RoundButton text={t('course.buyNow')} onClick={course.buyedCourse()? null: handlePay} endIcon={<PaymentsOutlined/>}/>
                                                    <RoundButton text="Añadir al carrito" onClick={() => {addToCart()}} endIcon={<AddShoppingCartOutlined/>} disabled={activeCart===null || (activeCart!==null && activeInCart)}/>
                                                </div>
                                            }
                                        </ThemeProvider>
                                        : <Skeleton sx={{borderRadius:2}} variant='rectangular' width={90} height={40}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="course-description">{course.getDescription()}</div>
                <Divider/>
                <div>
                    <div className="caps-public">
                        <div className="caps-title">{t('course.contentsIndex')}</div>
                        <div>
                            {course.getCaps().map(item => {
                                console.log("Item: " + item['title'] + " :: " + item['description']);
                                capIndex++;
                                return (<PublicCapView key={item['title']} last={course.getCapsLen() === capIndex? true : false} num={capIndex} title={item['title']} description={item['description']}/>);
                            })}
                        </div>
                    </div>
                    <div style={{height:5}}/>
                    <div className="video-public">
                        <div className="video-title">{t('course.presentationVideo')}</div>
                        <div style={{justifyContent: 'center', display:'flex', margin:5}}>
                            <iframe width="560" height="315" src={course.getVideoUrl()} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div style={{height:5}}/>
            </div> : <div className={isLaptop? "private-course-view" : "private-course-view-mobile"}>
                {isLaptop? <div style={{gridArea:'time'}}>
                    <div style={{height:'64px'}}/>
                    <CourseDrawerOther className={'timeline-view'} course={course} theme={theme} handleChangeView={handleChangeView} setCurrentPage={setCurrentPage} courseId={courseId} selected={selectedPart[0]}/>
                </div> : <Drawer open={openDrawer} onClose={closeDrawer}>
                    <CourseDrawerOther className={'timeline-view-mobile'} course={course} theme={theme} handleChangeView={handleChangeView} setCurrentPage={setCurrentPage} closeDrawer={closeDrawer} isDrawer={true} suficientDrawer={suficientDrawer} courseId={courseId} selected={selectedPart[0]}/>
                </Drawer>}
                {selectedPart[0] === course.getPrivateData().getChapters().length-1? <div className="main-view">
                    <div style={{height:'64px'}}/>
                    <div style={{margin:'7px'}}>
                        <div className="part-title">
                            {t('course.screens.finished.title')}
                        </div>
                        <Divider/>
                        <div className="course-data-view">
                            {course.getPrivateData().getNota() === null
                                ? <div style={{margin:'0px 40px'}}>
                                    <div>
                                        {t('course.screens.finished.msg')}
                                    </div>
                                    <div style={{height:'20px'}}/>
                                    <div style={{display:'flex'}}>
                                        <Button variant="outlined" sx={{width:'-webkit-fill-available'}}>{t('course.screens.finished.diploma')}</Button>
                                        <div style={{width:'10px'}}/>
                                        <Button variant="outlined" sx={{width:'-webkit-fill-available'}} onClick={() => {navigate(`/createTicket?type=0&course=${course.getId()}`)}}>{t('course.screens.finished.nota')}</Button>
                                    </div>
                                </div>
                                : <div className="main-view">
                                    
                                </div>
                            }
                        </div>
                    </div>
                </div> : <div className="main-view">
                    <div style={{height:'64px'}}/>
                    <Box sx={{ width: '100%', margin:'5px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                                <Tab style={{color: value===0? '#36454f' : '#708090'}} label={course.getIdioma() === "ESP"? "Video introductorio" : course.getIdioma() === "CAT"? "Vídeo introductori" : ""} icon={course.getPrivateData().getChapterAt(selectedPart[0]).getVideoComplated()? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>} iconPosition="end" {...a11yProps(0)}/>
                                {course.getPrivateData().getChapterAt(selectedPart[0]).getTopics().map((topic, tIndex) => {
                                    return <Tab key={tIndex+1} style={{color: value===tIndex+1? '#36454f' : '#708090'}} label={topic.getTitle()} icon={course.getPrivateData().getChapterAt(selectedPart[0]).getTopicAt(index).getComplated()? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>} iconPosition="end" {...a11yProps(tIndex+1)}/>
                                })}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div style={{margin:'7px'}}>
                                <div className="course-data-view">
                                    {course.getPrivateData().getChapterAt(selectedPart[0]).getVideoUrl()? <div style={{margin: '0px 20px 5px 20px'}}>
                                        <VideoPlayerApp url={course.getPrivateData().getChapterAt(selectedPart[0]).getVideoUrl()}/>
                                        <div style={{textAlign:'justify', marginTop:'30px'}}>
                                            <Markup content={course.getPrivateData().getChapterAt(selectedPart[0]).getDescription()}/>
                                        </div>
                                    </div> : <LoadingBarApp/>}
                                </div>
                                <Divider/>
                                <div className="docs-download">
                                    <div className="docs-dwl-title">
                                        Documentos de aprendizaje
                                    </div>
                                    <div className="docs-view">
                                        {course.getPrivateData().getChapterAt(selectedPart[0]).getDocs().map((docRef, index) => {
                                            return <DocDownload key={index} doc={docRef}/>
                                        })}
                                    </div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div style={{display:'flex', margin:'20px'}}>
                                    <Button sx={{marginLeft:'auto'}} variant="outlined" onClick={async () => {
                                        course.getPrivateData().getChapterAt(selectedPart[0]).updateVideoComplated(true);
                                        saveProgress();
                                        setValue(value+1);
                                    }}>
                                        Siguiente
                                    </Button>
                                </div>
                            </div>
                        </CustomTabPanel>
                        {course.getPrivateData().getChapterAt(selectedPart[0]).getTopics().map((topic, tIndex) => {
                            return <CustomTabPanel value={value} index={tIndex+1} key={tIndex+1}>
                                <div className="course-data-view">
                                    <div style={{margin: '0px 20px 5px 20px'}}>
                                        {<DrawTopic key={selectedPart[0] + ":" + value} topic={course.getPrivateData().getChapterAt(selectedPart[0]).getTopicAt(value-1)} chapter={selectedPart[0]} topicNum={value-1} saveProgress={saveProgress} saveCuestionario={saveCuestionarioDataBase} firstIdx={selectedPart[0]} lastIdx={value-1} courseId={courseId} userId={userr.uid} sendCuestionario={sendCuestionario} updateNotaTopic={handleUpdateNotaTopic} nextTopic={handleNextTopic} />}
                                    </div>
                                </div>
                            </CustomTabPanel>
                        })}
                    </Box>
                </div>}
                
            </div>}</div>}
        </div>
    }
    
    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}