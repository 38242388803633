import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginView from './routes/login';
import Dashboard from './routes/dashboard';
import Logout from './routes/logout';
import DashboardProfile from './routes/dashboard-profile';
import PublicProfile from './routes/public-profile';
import HomePage from './routes/home';
import Course from './routes/course';
import NotFound from './routes/not-found';
import Account from './routes/account';
import BrowseCourses from './routes/browse';
import CoursePage from './routes/course';
import PaymentAccepted from './routes/payment-accepted';
import PaymentCancelled from './routes/payment-cancelled';
import RecoverPassword from './routes/recover-password';
import Resourses from './routes/resources';
import TicketPage from './routes/tickets';
import TicketView from './routes/ticket';
import CreateTicket from './routes/create-ticket';
import TeachersPage from './routes/teachers-page';
import TeachersCourse from './routes/teachers-course';
import TeachersCourses from './routes/teachers-courses';
import Forums from './routes/forums';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<HomePage/>}/>
      <Route path='login' element={<LoginView/>}/>
      <Route path='logout' element={<Logout/>}/>
      <Route path='recoverPassword' element={<RecoverPassword/>}/>
      <Route path='dashboard' element={<Dashboard/>}/>
      <Route path='dashboard/profile' element={<DashboardProfile/>}/>
      <Route path='dashboard/account' element={<Account/>}/>
      <Route path='u/:userid' element={<PublicProfile/>}/>
      <Route path='course/:courseId' element={<CoursePage/>}/>
      <Route path='resources/:courseId' element={<Resourses/>}/>
      <Route path='forums/:courseId' element={<Forums/>}/>
      <Route path='browse' element={<BrowseCourses/>}/>
      <Route path='paymentSucceded' element={<PaymentAccepted/>}/>
      <Route path='paymentCancelled' element={<PaymentCancelled/>}/>
      <Route path='tickets' element={<TicketPage/>}/>
      <Route path='ticket/:ticketId' element={<TicketView/>}/>
      <Route path='createTicket' element={<CreateTicket/>}/>
      <Route path='teachers' element={<TeachersPage/>}/>
      <Route path='teachers/course/:courseId' element={<TeachersCourse/>}/>
      <Route path='teachers/courses' element={<TeachersCourses/>}/>
      <Route path='*' element={<NotFound/>}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
