import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton, ThemeProvider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CourseBtn from "./course-tema-btn";
import PublicIcon from '@mui/icons-material/Public';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ForumIcon from '@mui/icons-material/Forum';

export default function CourseDrawer({course, theme, handleChangeView, setCurrentPage, className, suficientDrawer, isDrawer=false, closeDrawer, courseId}) {
    var index = 0;
    return <div className={className}>
        {isDrawer&&!suficientDrawer? <div style={{display:'flex', margin:'5px'}}>
            <ThemeProvider theme={theme}>
                <IconButton onClick={closeDrawer} sx={{marginLeft:'auto'}} color="primary" aria-label="add to shopping cart">
                    <FirstPageIcon/>
                </IconButton>
            </ThemeProvider>
        </div> : <div></div>}
        {course.getPrivateData().getChapters().map(chapter => {
            index++;
            console.log(chapter);
            var tIndex = 0;
            return <Accordion key={index} disabled={!(index-1===0 || (index>1 && course.getPrivateData().getChapterAt(index-2).getComplated()))} sx={{backgroundColor:chapter.getComplated()? '#7ec281' : 'white'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Tema {index}: {chapter.getTitle()}
                </AccordionSummary>
                <AccordionDetails>
                    <ThemeProvider theme={theme}>
                        {course.getPrivateData().getChapters().length !== index
                            ? <CourseBtn handleOnClick={handleChangeView} index={index-1} tIndex={-1} sx={{backgroundColor:chapter.getVideoComplated()? '#7ec281' : 'white', width:'-webkit-fill-available'}}>
                                <div>
                                    <div>
                                        Vídeo explicativo
                                    </div>
                                    <Divider/>
                                    <div style={{color:'grey', fontSize:'10px'}}>
                                        Vídeo explicativo y/o introductorio de la unidad.
                                    </div>
                                </div>
                            </CourseBtn>
                            : <CourseBtn handleOnClick={handleChangeView} index={index-1} tIndex={-1} sx={{backgroundColor:'white', width:'-webkit-fill-available'}}>
                                <div>
                                    <div>
                                        Finalización del curso
                                    </div>
                                    <Divider/>
                                    <div style={{color:'grey', fontSize:'10px'}}>
                                        Nota y revisión del curso.
                                    </div>
                                </div>
                            </CourseBtn>
                        }
                        {chapter.getTopics().map(topic => {
                            tIndex++;
                            console.log("The topic now is: ");
                            console.log(topic);
                            return <CourseBtn key={tIndex} handleOnClick={handleChangeView} index={index-1} tIndex={tIndex-1} sx={{backgroundColor:chapter.getTopicAt(tIndex-1).getComplated()? '#7ec281' : 'white', width:'-webkit-fill-available'}} disabled={!((tIndex===1 && chapter.getVideoComplated()) || (tIndex>1 && chapter.getTopicAt(tIndex-2).getComplated()))}>
                                <div>
                                    <div>
                                        Apartado {tIndex}: {topic.getTitle()}
                                    </div>
                                    <Divider/>
                                    <div style={{color:'grey', fontSize:'10px'}}>
                                        {topic.getDescription()}
                                    </div>
                                </div>
                            </CourseBtn>;
                    })}
                    </ThemeProvider>
                </AccordionDetails>
            </Accordion>;
        })}
        <div style={{height:'10px'}}/>
        <Button variant="outlined" onClick={() => {
            window.open(window.location.origin+"/resources/"+courseId)
        }} endIcon={<ImportContactsIcon/>} color="primary" sx={{margin:'5px', width:'-webkit-fill-available'}}>
            Ver recursos de aprendizage
        </Button>
        <Button variant="outlined" onClick={() => {
            setCurrentPage(0);
        }} endIcon={<PublicIcon/>} color="primary" sx={{margin:'5px', width:'-webkit-fill-available'}}>
            Ver página pública
        </Button>
    </div>
}

export function CourseDrawerOther({course, theme, handleChangeView, setCurrentPage, className, suficientDrawer, isDrawer=false, closeDrawer, courseId, selected}) {
    return <div className={className}>
        {isDrawer&&!suficientDrawer? <div style={{display:'flex', margin:'5px'}}>
            <ThemeProvider theme={theme}>
                <IconButton onClick={closeDrawer} sx={{marginLeft:'auto'}} color="primary" aria-label="add to shopping cart">
                    <FirstPageIcon/>
                </IconButton>
            </ThemeProvider>
        </div> : <div></div>}
        {course.getPrivateData().getChapters().map((chapter, index) => {
            console.log(chapter);
            var classN = "tema-button";

            if (isDrawer) {
                classN = classN + " is-drawer";
            }

            if(course.getPrivateData().getChapterAt(index).getComplated())
                classN = classN + " finished";

            if (!(index === 0 || (index>0 && course.getPrivateData().getChapterAt(index-1).getComplated())))
                classN = classN + " disabled";

            if (index === selected)
                classN = classN + " selected"

            return <div key={index} className={classN} onClick={() => {
                if (index === 0 || (index>0 && course.getPrivateData().getChapterAt(index-1).getComplated())) {
                    handleChangeView(index, 0)
                }
            }}>
                <div style={{margin:'5px', textAlign: 'center'}}>
                    {course.getPrivateData().getChapters().length-1 !== index
                        ? `Tema ${index+1}: `
                        : ""
                    }{chapter.getTitle()}
                </div>
            </div>;
        })}
        <div style={{height:'10px'}}/>
        <Divider/>
        <div style={{height:'7px'}}/>
        <div className="tema-button is-drawer" onClick={() => {window.open(window.location.origin+"/resources/"+courseId);}}>
            <div style={{margin:'5px', textAlign: 'center'}}>
                Ver recursos de aprendizage <span style={{verticalAlign:'middle'}}><ImportContactsIcon/></span>
            </div>
        </div>
        <div className="tema-button is-drawer" onClick={() => {window.open(window.location.origin+"/forums/"+courseId);}}>
            <div style={{margin:'5px', textAlign: 'center'}}>
                Foros del curso <span style={{verticalAlign:'middle'}}><ForumIcon/></span>
            </div>
        </div>
        <div className="tema-button is-drawer" onClick={() => {setCurrentPage(0);}}>
            <div style={{margin:'5px', textAlign: 'center'}}>
                Ver página pública <span style={{verticalAlign:'middle'}}><PublicIcon/></span>
            </div>
        </div>
    </div>
}