import { useState } from "react";
import { AuthProviderTeacher } from "../components/auth-provider";
import { CourseViwerCardTeacher } from "../components/course-viwer";
import LoadingBarApp from "../components/loading";
import Navbar from "../components/navbar";
import Section, { ExpandableSection } from "../components/section";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Course } from "../implementation/course";

export default function TeachersCourses() {
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const [userr, setUserr] = useState(null);
    const [reload, setReload] = useState(true);
    const [publishedCourses, setPublishedCourses] = useState([]);
    const [notCourses, setNotCourses] = useState([]);

    function handleOnUserLoggedIn(user) {
        setState(1);
        setUserr(user);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    function handleUserNotTeacher() {
        navigate("/");
    }

    async function getCourses() {
        try {
            const coursesQ = await getDocs(query(collection(db, "courses"), where("teachers", "array-contains", userr.uid), where("active", "==", true)));
            console.log(coursesQ);

            await Promise.all(coursesQ.docs.map(async (courseData, index) => {
                console.log(courseData);

                const course = Course.fromDataBase(courseData);
                console.log(course);

                publishedCourses.push(course);
            }));

            const coursesQN = await getDocs(query(collection(db, "courses"), where("teachers", "array-contains", userr.uid), where("active", "==", false)));

            await Promise.all(coursesQN.docs.map(async (courseData, index) => {
                console.log(courseData);

                const course = Course.fromDataBase(courseData);
                console.log(course);

                notCourses.push(course);
            }));

            setReload(false);
        } catch (e) {
            console.error(e);
        }
    }

    if (state === 1) {
        if (reload) {
            getCourses();
        }

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <ExpandableSection title="Cursos publicados">
                {reload
                    ? <LoadingBarApp/>
                    : publishedCourses.length !== 0
                        ? <div className="grid">{publishedCourses.map((course, index) => {
                            return <CourseViwerCardTeacher key={index} course={course} progress={false} user={userr}/>
                        })}</div>
                        : <div style={{margin:'5px'}}>Aún no hay ningún curso publicado</div>
                }
            </ExpandableSection>
            <ExpandableSection title="Cursos no publicados">
                {reload
                    ? <LoadingBarApp/>
                    : notCourses.length !== 0
                        ? <div className="grid">{notCourses.map((course, index) => {
                            return <CourseViwerCardTeacher key={index} course={course} progress={false} user={userr}/>
                        })}</div>
                        : <div style={{margin:'5px'}}>Aún no hay ningún curso no publicado</div>
                }
            </ExpandableSection>
        </div>
    }

    return <AuthProviderTeacher
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
        onUserNotTeacher={handleUserNotTeacher}>
        <LoadingBarApp/>
    </AuthProviderTeacher>
}