import { Button, Collapse, Dialog, DialogActions, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import LoadingBarApp from "./loading"
import { useState } from "react"
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Chapter, Course, PrivateCourse } from "../implementation/course";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function DialogSelectTemas({ open, onClose, courseId, onSelect }) {
    const [course, setCourse] = useState(null);
    const [opened, setOpened] = useState([]);
    const [fakeState, setFakeState] = useState(false);
    const [selected, setSelected] = useState([]);

    async function getTemas() {
        const docRef = await getDoc(doc(db, 'courses', courseId));
        console.log(docRef.data());

        var privateCourseData = null;
        const privateCourse = await getDocs(collection(db, 'courses', courseId, 'chapters'));
        const chapters = [];
        privateCourse.forEach(item => {
            console.log(item.id + " :: " + item.get('title'));
            chapters.push(Chapter.fromDataBase(item));
        });
        chapters.push(new Chapter("Finalización", "Finalización y revisión del curso.", [], null, null, null, false, false));

        console.log(chapters);
        privateCourseData = new PrivateCourse(chapters);

        const courseData = Course.fromDataBase(docRef);
        courseData.updatePrivateCourse(privateCourseData);

        setCourse(courseData);
    }

    if (course === null) {
        getTemas();
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>Seleccionar temas</DialogTitle>
        <Divider/>
        {course !== null
            ? <List>
                {course.getPrivateData().getChapters().map((chapter, index) => {
                    if (opened.length === 0 || opened.length-1<index) {
                        opened.push(false);
                    }

                    if (selected.length === 0 || selected.length-1<index) {
                        selected.push([]);
                    }

                    return <div key={index}>
                        <ListItemButton onClick={() => {opened[index] = !opened[index]; setFakeState(!fakeState)}}>
                            <ListItemText primary={index+1 + ': ' +chapter.getTitle()} />
                            {opened[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={opened[index]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {chapter.getTopics().map((topic, tIndex) => {
                                    if (selected[index].length === 0 || selected[index].length-1 < index) {
                                        selected[index].push(false);
                                    }

                                    return <ListItemButton key={tIndex} sx={{ pl: 4 }} onClick={() => {
                                        selected[index][tIndex] = !selected[index][tIndex];
                                        setFakeState(!fakeState);
                                    }}>
                                        <ListItemIcon>
                                            {selected[index][tIndex]
                                                ? <CheckBoxIcon/>
                                                : <CheckBoxOutlineBlankIcon/>
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={tIndex + ': ' + topic.getTitle()} />
                                    </ListItemButton>
                                })}
                                <Divider/>
                            </List>
                        </Collapse>
                    </div>
                })}
            </List>
            : <LoadingBarApp/>
        }
        <DialogActions>
            <Button onClick={onClose}>Cancelar</Button>
            <Button onClick={() => {
                const newSelected = [];

                selected.forEach((chapter, index) => {
                    chapter.forEach((topic, tIndex) => {
                        if (topic) {
                            console.log(`${index}:${tIndex}`);

                            newSelected.push({
                                'chapter': `tema ${index+1} (${course.getPrivateData().getChapterAt(index).getTitle()})`,
                                'topic': `módulo ${tIndex+1} (${course.getPrivateData().getChapterAt(index).getTopicAt(tIndex).getTitle()})`
                            })
                        }
                    })
                })

                onSelect(newSelected);
            }}>Aplicar</Button>
        </DialogActions>
    </Dialog>
}