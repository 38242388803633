import { useNavigate, useParams } from "react-router-dom";
import AuthProvider from "../components/auth-provider";
import LoadingBarApp from "../components/loading";
import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { PublicUser } from "../implementation/publicUser";
import Navbar from "../components/navbar";
import { Avatar } from "@mui/material";

const avatarSize = '50px';

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function stringAvatar(name) {
    name = name.replace('_', ' ');
    name = name.replace('-', ' ');
    name = name.toUpperCase();

    if (name.split(' ').length > 1) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: avatarSize,
                height: avatarSize
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: avatarSize,
                height: avatarSize
            },
            children: `${name.split(' ')[0][0]}`,
        };
    }    
}

export default function PublicProfile() {
    const {userid} = useParams();
    const navigate = useNavigate();
    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);
    const [userData, setUserData] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getUserData() {
        try {
            const docRef =  await getDoc(doc(db, 'publicUsers', userid));
            setUserData(PublicUser.fromDatabase(docRef));
        } catch (e) {
            console.error(e);
            setUserData(new PublicUser('', ''));
        }
    }

    if (state === 1) {
        if (userData === null) 
            getUserData();
        
        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            {userData!=null
                ? <div style={{margin:'10px 20px'}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Avatar {...stringAvatar(userData.getName())}/>
                        <div style={{width:'10px'}}/>
                        <div style={{fontSize:'24px', fontWeight:'600'}}>
                            {userData.getName()}
                        </div>
                    </div>
                    <div style={{height:'5px'}}/>
                    <div>
                        {userData.getDescription()}
                    </div>
                </div>
                : <LoadingBarApp/>
            }
        </div>
    }

    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>
}